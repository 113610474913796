import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CountryRegionData } from 'react-country-region-selector';
import { type FieldErrors } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import type { AddressFormState, CountryOption, StateOption } from '@bladebinge/types';
import { StyledHookFormErrorMessage } from '../styled-shared';

const getRegions = (country: string[]) => {
    if (!country) {
        return [];
    }

    return (country[2] || '').split('|').map((regionPair) => {
        const [name, shortCode] = regionPair.split('~');
        return {
            name,
            shortCode
        };
    });
};

export const CountryRegionSelector = ({
    errors = {},
    handleChangeCountry,
    handleChangeRegion
}: {
    readonly errors?: FieldErrors<AddressFormState>;
    readonly handleChangeCountry: (countryOption: CountryOption) => void;
    readonly handleChangeRegion: (stateOption: StateOption) => void;
}) => {
    const { t } = useTranslation();

    // United States as default;
    const DEFAULT_COUNTRY =
        (CountryRegionData as string[][]).find(([_name, code, _states]) => code === 'US') ??
        (CountryRegionData as string[][])[235]; //  find is preferred since the indexes have changed over time
    // [
    //     'United States',
    //     'US',
    //     'Alabama~AL|Alaska~AK|American Samoa~AS|Arizona~AZ|Arkansas~AR|California~CA|Colorado~CO|Connecticut~CT|Delaware~DE|District of Columbia~DC|Micronesia~FM|Florida~FL|Georgia~GA|Guam~GU|Hawaii~HI|Idaho~ID|Illinois~IL|Indiana~IN|Iowa~IA|Kansas~KS|Kentucky~KY|Louisiana~LA|Maine~ME|Marshall Islands~MH|Maryland~MD|Massachusetts~MA|Michigan~MI|Minnesota~MN|Mississippi~MS|Missouri~MO|Montana~MT|Nebraska~NE|Nevada~NV|New Hampshire~NH|New Jersey~NJ|New Mexico~NM|New York~NY|North Carolina~NC|North Dakota~ND|Northern Mariana Islands~MP|Ohio~OH|Oklahoma~OK|Oregon~OR|Palau~PW|Pennsylvania~PA|Puerto Rico~PR|Rhode Island~RI|South Carolina~SC|South Dakota~SD|Tennessee~TN|Texas~TX|Utah~UT|Vermont~VT|Virgin Islands~VI|Virginia~VA|Washington~WA|West Virginia~WV|Wisconsin~WI|Wyoming~WY|Armed Forces Americas~AA|Armed Forces Europe, Canada, Africa and Middle East~AE|Armed Forces Pacific~AP'
    // ];

    const [country, setCountry] = useState<string[]>(DEFAULT_COUNTRY);
    const [region, setRegion] = useState<string>('');
    const currentRegionData = getRegions(country);

    const selectCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
        const countrySelection = e.target.value as unknown as string[];
        setCountry(countrySelection);
        setRegion('');
        const countryOption = {
            name: countrySelection[0],
            shortCode: countrySelection[1]
        };
        handleChangeCountry(countryOption);
    };

    const selectRegion = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegion(e.target.value);
        const regionOption = currentRegionData.find(({ name }) => name === e.target.value);
        if (regionOption) {
            handleChangeRegion(regionOption);
        }
    };

    return (
        <Grid container direction="column">
            <Grid>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.city')}
                    fullWidth
                    id="regionSelector"
                    label={t('common:address_form.state_region')}
                    margin="normal"
                    name="regionSelector"
                    onChange={selectRegion}
                    select
                    value={region}
                    variant="outlined"
                >
                    {currentRegionData.map(({ name: option, shortCode }) => (
                        <MenuItem key={option} value={option} data-shortcode={shortCode}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <StyledHookFormErrorMessage
                    as="div"
                    errors={errors}
                    message={t('common:address_form.errors.invalid_state')}
                    name="state"
                />
            </Grid>
            <Grid>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.country_name')}
                    fullWidth
                    id="countrySelector"
                    label={t('common:address_form.country')}
                    margin="normal"
                    name="countrySelector"
                    onChange={selectCountry}
                    select
                    value={country}
                    variant="outlined"
                >
                    {CountryRegionData.map((option) => (
                        <MenuItem key={option[0]} data-shortcode={option[1]} value={option}>
                            {option[0]}
                        </MenuItem>
                    ))}
                </TextField>
                <StyledHookFormErrorMessage
                    as="div"
                    errors={errors}
                    message={t('common:address_form.errors.invalid_country')}
                    name="country"
                />
            </Grid>
        </Grid>
    );
};
