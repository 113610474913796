import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import type { ListingGraph, ShoppingCartItem } from '@bladebinge/types';
import { useMe } from '../../context/me/me-context';
import { useShoppingCartContext } from '../../context/shopping-cart/shopping-cart-context';
import { useMakeAnOfferContext } from '../../context/make-an-offer/make-an-offer-context';
import { useRedirectToLogin } from '../../hooks/use-redirect-to-login';
import { useUserPreferences } from '../../context/user-preferences/user-preferences-context';
import { ItemQuantitySelector } from './ItemQuantitySelector';

export const PurchaseButtons = ({
    className = '',
    listing,
    ownerId,
    size = 'medium',
    showBuyNow = true
}: {
    readonly className?: string;
    readonly listing: ListingGraph;
    readonly ownerId?: string;
    readonly size: 'small' | 'medium' | 'large';
    readonly showBuyNow?: boolean;
}) => {
    const { t } = useTranslation();
    const router = useRouter();
    const redirectToLogin = useRedirectToLogin();
    const { persistedUserPreferences } = useUserPreferences();
    const { activePurchaseOfferListingIds, id: loggedInUserId } = useMe();
    const { addListingToOffer, isOfferingEnabled } = useMakeAnOfferContext();
    const { addItemsToCart, setShowCartDrawer, cartData } = useShoppingCartContext();
    const { items: itemsInCart } = cartData;

    const { quantity: onLoadCartQuantity = 0 } = itemsInCart?.[listing?.id] ?? {};

    const [addToCartQuantity, setAddToCartQuantity] = useState<number>(1);
    const [quantityInCart, setQuantityInCart] = useState<number>(onLoadCartQuantity);

    const {
        offersSellerPaidDomesticShipping,
        offersSellerPaidInternationalShipping,
        price,
        quantityAvailable,
        status
    } = listing ?? {};

    const quantityRemaining = useMemo(() => quantityAvailable - quantityInCart, [quantityAvailable, quantityInCart]);

    const cartItem: ShoppingCartItem = useMemo(
        () => ({
            id: listing?.id,
            offersSellerPaidDomesticShipping,
            offersSellerPaidInternationalShipping,
            price,
            quantity: addToCartQuantity
        }),
        [listing?.id, offersSellerPaidDomesticShipping, offersSellerPaidInternationalShipping, price, addToCartQuantity]
    );

    const ownedByLoggedInUser = ownerId && ownerId === loggedInUserId;

    const addToCartAndBuyIfPossible = useCallback(() => {
        // only add to cart on buy now if not already there
        if (quantityInCart === 0) {
            addItemsToCart(cartItem);
        }

        router.push('/cart');
    }, [addItemsToCart, cartItem, quantityInCart, router]);

    const addToCartAndShowSideDrawer = useCallback(() => {
        addItemsToCart(cartItem);
        setShowCartDrawer(true);
    }, [addItemsToCart, cartItem, setShowCartDrawer]);

    const startOffer = () => {
        addListingToOffer(listing);
    };

    useEffect(() => {
        const { items } = cartData;
        const { quantity: currentCartQuantity = 0 } = items?.[listing?.id] ?? {};
        setQuantityInCart(currentCartQuantity);
    }, [cartData, listing?.id]);

    useEffect(() => {
        const { quantity: updatedCartQuantity = 0 } = itemsInCart?.[listing?.id] ?? {};
        setQuantityInCart(updatedCartQuantity);
    }, [itemsInCart, listing?.id]);

    const ownerAllowsOffers = useMemo(
        () => listing?.isOfferEnabled && Boolean(listing?.ownerProfile?.allowsOffers),
        [listing?.isOfferEnabled, listing?.ownerProfile]
    );

    const ownerAllowsTrades = useMemo(
        () =>
            listing?.isTradeEnabled &&
            Boolean(listing?.ownerProfile?.allowsTrades) &&
            getGlobalStaticFeatureEnablement('EnableTrades', loggedInUserId),
        [loggedInUserId, listing]
    );

    const loggedInUserHasEnabledTrades = useMemo(
        () => persistedUserPreferences?.enableTradeOffersOption,
        [persistedUserPreferences]
    );

    const offerButtonText = useMemo(
        () =>
            activePurchaseOfferListingIds.has(listing?.id)
                ? t('common:purchase_offer.awaiting_response')
                : ownerAllowsTrades && loggedInUserHasEnabledTrades
                  ? t('common:purchase_offer.offer_or_trade')
                  : t('common:listing.make_an_offer'),
        [activePurchaseOfferListingIds, listing?.id, loggedInUserHasEnabledTrades, ownerAllowsTrades, t]
    );

    if (ownedByLoggedInUser) {
        return (
            <Typography sx={{ color: 'info.main', textAlign: 'center', height: '100%' }} variant="body1">
                {t('common:listing.your_listing')}
            </Typography>
        );
    }

    if (status === 'SOLD_OUT') {
        return (
            <Typography
                variant="body1"
                component="div"
                sx={{
                    // need to match height with purchase buttons in larger views
                    minHeight: {
                        xs: 'auto',
                        sm: '78px'
                    },
                    fontStyle: 'italic',
                    textAlign: 'center'
                }}
            >
                {t('common:listing_manager.statuses.sold_out')}
            </Typography>
        );
    }

    return (
        <Grid
            container
            direction="column"
            className={className}
            sx={{
                position: 'relative',
                textAlign: 'center'
            }}
        >
            {quantityRemaining > 1 && (
                <Grid sx={{ my: 0.5 }} size={12}>
                    <ItemQuantitySelector
                        defaultSelection={1}
                        fullWidth
                        itemId={listing?.id}
                        max={quantityRemaining}
                        onSelect={setAddToCartQuantity}
                    />
                </Grid>
            )}
            {showBuyNow && (
                <Grid sx={{ my: 0.5 }} size={12}>
                    <Button
                        color="primary"
                        className="buy-now-btn"
                        disabled={quantityInCart === 0 && quantityRemaining < 1}
                        fullWidth
                        onClick={addToCartAndBuyIfPossible}
                        size={size}
                        sx={{ overflowWrap: 'anywhere' }}
                        variant="contained"
                    >
                        {t('common:listing.buy_now')}
                    </Button>
                </Grid>
            )}
            <Grid sx={{ my: 0.5 }} size={12}>
                <Button
                    color={showBuyNow ? 'secondary' : 'primary'}
                    className="add-to-cart-btn"
                    disabled={quantityRemaining < 1}
                    fullWidth
                    onClick={addToCartAndShowSideDrawer}
                    size={size}
                    sx={{ overflowWrap: 'anywhere' }}
                    variant="contained"
                >
                    {t('common:listing.add_to_cart')}
                </Button>
            </Grid>
            {isOfferingEnabled && !ownedByLoggedInUser && (
                <Grid sx={{ my: 0.5 }} size={12}>
                    <Button
                        color="inherit"
                        className="make-an-offer-btn"
                        disabled={
                            quantityRemaining < 1 ||
                            activePurchaseOfferListingIds.has(listing?.id) ||
                            !ownerAllowsOffers
                        }
                        fullWidth
                        onClick={loggedInUserId ? startOffer : () => redirectToLogin()}
                        size={size}
                        sx={{ overflowWrap: 'anywhere' }}
                        variant="outlined"
                    >
                        {offerButtonText}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};
