import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Crop } from 'react-image-crop';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ImageCropper } from './ImageCropper';
import type { VerifiedPercentageCrop } from './utils/types';
import type { ImageDataWithCropInfo, ImageUpload } from './types';

interface PercentCropMap {
    [filePath: string]: Crop;
}

interface DataMapByPath {
    [filePath: string]: ImageUpload;
}

export const CropGrid = ({
    rawImageFiles = [],
    onCancel,
    onSave
}: {
    readonly rawImageFiles: ImageUpload[];
    readonly onCancel: () => void;
    readonly onSave: (data: ImageDataWithCropInfo[]) => void;
}) => {
    const { t } = useTranslation();
    // todo map image data
    const [percentCrops, setPercentCrops] = useState<PercentCropMap>({});
    const [processedUploads, setProcessedUploads] = useState<DataMapByPath>({});

    useEffect(() => {
        // have to reset if raw data updates
        setProcessedUploads(
            rawImageFiles.reduce((acc, { name, dataUri }) => {
                acc[name] = {
                    dataUri,
                    name
                };
                return acc;
            }, {} as DataMapByPath)
        );
    }, [rawImageFiles, setProcessedUploads]);

    if (rawImageFiles.length === 0) {
        return null;
    }

    const onCropperUpdateImage = ({ name, dataUri }: { name: string; dataUri: string }) => {
        setProcessedUploads((prevData) => {
            prevData[name] = {
                name,
                dataUri
            };
            return prevData;
        });
    };

    // TODO need to adjust so crop data is saved with processed uploads info
    const saveCrops = () => {
        const imageDataWithCrops: ImageDataWithCropInfo[] = Object.keys(processedUploads).map((name: string) => {
            (processedUploads[name] as ImageDataWithCropInfo).crop = percentCrops[name] as VerifiedPercentageCrop;
            return processedUploads[name] as ImageDataWithCropInfo;
        });

        onSave(imageDataWithCrops);
    };

    return (
        <Grid container>
            <Grid container justifyContent="flex-start" spacing={2}>
                {Object.values(processedUploads).map(({ dataUri, name = '' }: ImageUpload) => (
                    <Grid
                        key={`${name}_grid_item`}
                        size={{
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }}
                    >
                        <ImageCropper
                            name={name}
                            src={dataUri ?? ''}
                            onComplete={(_crop: Crop, percentage: Crop) => {
                                setPercentCrops((prevPercentCrops) => ({
                                    ...prevPercentCrops,
                                    [name]: percentage
                                }));
                            }}
                            onUpdateImage={onCropperUpdateImage}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ width: '100%', my: 2 }}>
                <Grid size={{ xs: 6, sm: 4 }}>
                    <Button fullWidth onClick={onCancel} variant="contained" size="large">
                        {t('common:images.image_editor.change_images')}
                    </Button>
                </Grid>
                <Grid size={{ xs: 6, sm: 4 }}>
                    <Button
                        id="save_cropped_images_btn"
                        fullWidth
                        onClick={saveCrops}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        {t('common:images.image_editor.save_cropped_images')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
