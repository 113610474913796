import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { UserInterfaceNotificationMenuItemTypes } from '@bladebinge/types';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Typography from '@mui/material/Typography';
import { useMe } from '../../../context/me/me-context';
import { useUserInterfaceNotificationsContext } from '../../../context/user-interface-notifications/user-interface-notifications-context';
import { ConfirmDialog } from '../../atoms/ConfirmDialog';
import { getNotificationMenuLinkHref } from './utils/get-notification-menu-link-href';
import { NotificationMenuDisplayTypeIcon } from './NotificationMenuDisplayTypeIcon';

export const StyledNotifcationLinkWrap = styled('a')(({ theme }) => ({
    display: 'block',
    width: '100%',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
        backgroundColor: 'rgba(224, 224, 224, 0.15)'
    }
}));

export const NotificationMenuListItem = ({
    count,
    displayType
}: {
    readonly displayType: UserInterfaceNotificationMenuItemTypes;
    readonly count: number;
}) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const [confirmDismissalDialogOpen, setConfirmDismissalDialogOpen] = useState(false);
    const { handleBulkDismissal, isDismissingNotifications } = useUserInterfaceNotificationsContext();

    const handleDismissalClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setConfirmDismissalDialogOpen(true);
    };

    if (count === 0) {
        return null;
    }

    const menuLink = getNotificationMenuLinkHref({ displayType, loggedInUserId });

    const itemContent = (
        <Grid container alignContent="space-between" alignItems="center" spacing={1}>
            <Grid size={1}>
                <IconButton
                    aria-label={t('common:notifications_menu.dismiss_all')}
                    color="secondary"
                    disabled={isDismissingNotifications}
                    edge="start"
                    onClick={handleDismissalClick}
                    size="small"
                    title={t('common:notifications_menu.dismiss_all_of_type', {
                        displayType: t(`common:notifications_menu.display_types.${displayType}`)
                    })}
                >
                    <RemoveCircleOutlineIcon sx={{ fontSize: '1.25rem' }} />
                </IconButton>
            </Grid>
            <Grid size={1}>
                <NotificationMenuDisplayTypeIcon displayType={displayType} sx={{ fontSize: '20px', mt: 1, ml: 0.5 }} />
            </Grid>
            <Grid size={9}>
                <Typography variant="body1" sx={{ fontSize: { xs: '.9em', sm: '1em' } }}>
                    {t(`common:notifications_menu.display_types.${displayType}`)}
                </Typography>
            </Grid>
            <Grid size={1}>
                <Badge badgeContent={count} color="primary" max={99} overlap="rectangular" variant="standard" />
            </Grid>
            <ConfirmDialog
                confirmButtonText={t('common:general.confirm')}
                denyButtonText={t('common:general.cancel')}
                onConfirm={() => handleBulkDismissal(displayType)}
                open={confirmDismissalDialogOpen}
                setOpen={setConfirmDismissalDialogOpen}
                title={t('common:notifications_menu.dismiss_all_of_type', {
                    displayType: t(`common:notifications_menu.display_types.${displayType}`)
                })}
                titleIcon={<RemoveCircleOutlineIcon color="error" sx={{ mr: 1, position: 'relative', top: '4px' }} />}
            >
                <Typography variant="body1">
                    {t('common:notifications_menu.dismiss_notifications_count', { count })}
                </Typography>
            </ConfirmDialog>
        </Grid>
    );

    return (
        <ListItem
            sx={{
                cursor: 'pointer',
                px: 0,
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: 'divider',
                '&:last-child': {
                    borderBottomColor: 'transparent'
                }
            }}
        >
            {menuLink ? (
                <StyledNotifcationLinkWrap href={menuLink}>{itemContent}</StyledNotifcationLinkWrap>
            ) : (
                itemContent
            )}
        </ListItem>
    );
};
