import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Crop, default as ReactImageCrop } from 'react-image-crop';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import 'react-image-crop/dist/ReactCrop.css';
import { getRotatedImageBlob } from './utils/get-rotated-image-blob';

const MIN_WIDTH = 40;
const MIN_HEIGHT = 40;

const DEFAULT_CROP: Crop = {
    x: 0,
    y: 0,
    unit: 'px',
    width: MIN_WIDTH,
    height: MIN_HEIGHT
};

// interface ReactCropProps {
//     src: string;
//     crop?: Crop;
//     imageAlt?: string;
//     minWidth?: number;
//     minHeight?: number;
//     maxWidth?: number;
//     maxHeight?: number;
//     keepSelection?: boolean;
//     onChange: (crop: Crop, percentCrop: PercentCrop) => void;
//     onComplete?: (crop: Crop, percentCrop: PercentCrop) => void;
//     onImageLoaded?: (target: HTMLImageElement) => void;
//     onDragStart?: () => void;
//     onDragEnd?: () => void;
//     disabled?: boolean;
//     crossorigin?: 'anonymous' | 'use-credentials';
//     children?: ReactNode;
//     style?: CSSProperties;
//     imageStyle?: CSSProperties;
//     onImageError?: (event: React.SyntheticEvent<HTMLImageElement>) => void;
//     className?: string;
//     locked?: boolean;
//     renderComponent?: ReactNode;
//     renderSelectionAddon?: (state: any) => ReactNode;
//     ruleOfThirds?: boolean;
//     circularCrop?: boolean;
// }

export const ImageCropper = ({
    onComplete,
    onUpdateImage,
    name,
    src
}: {
    readonly onComplete: (crop: Crop, percentCrop: Crop) => void;
    readonly onUpdateImage: ({ name, dataUri }: { name: string; dataUri: string }) => void;
    readonly name: string;
    readonly src: string;
}) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState<Crop>(DEFAULT_CROP);
    const [currentSrc, setCurrentSrc] = useState<string>(src);
    const onChange = (crop: Crop) => {
        const { width = 0, height = 0 } = crop;

        // user likely clicked outside the crop. In any case we don't want zero math
        if (width === 0 || height === 0) {
            return;
        }

        setCrop(crop);
    };

    const onImageLoaded = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        const { width, height } = e.currentTarget;
        const side = Math.min(width, height) * 0.99;

        const loadCrop = {
            ...crop,
            x: 2,
            y: 2,
            height: side,
            width: side
        };

        // For some reason the browser won't let you interact if the crop uses full image dimensions.  Shrinking a tiny bit so the handles may be grabbed
        setCrop(loadCrop);

        return false; // Return false when setting crop state in here.
    };

    const rotate = async (dir: 'left' | 'right') => {
        const degrees = dir === 'left' ? -90 : 90;
        const updatedImage = await getRotatedImageBlob(currentSrc, degrees);
        setCurrentSrc(updatedImage.dataUri);
        onUpdateImage({
            name,
            dataUri: updatedImage.dataUri
        });
    };

    const handleRotate = async (e: React.MouseEvent) => (e.shiftKey ? rotate('right') : rotate('left'));

    // ReactImageCropper requires a img html tag rather than a NextImage
    return (
        <Grid container justifyContent="center" spacing={1}>
            <Grid
                size={{
                    xs: 10,
                    sm: 10,
                    lg: 10,
                    xl: 10
                }}
            >
                <ReactImageCrop
                    crop={crop}
                    aspect={1}
                    minWidth={MIN_WIDTH}
                    minHeight={MIN_HEIGHT}
                    maxWidth={1000}
                    maxHeight={1000}
                    onChange={onChange}
                    onComplete={onComplete}
                    ruleOfThirds
                >
                    <img alt="" src={currentSrc} onLoad={onImageLoaded} />
                </ReactImageCrop>
            </Grid>
            <Grid
                size={{
                    xs: 1,
                    sm: 1,
                    lg: 1,
                    xl: 1
                }}
            >
                <div>
                    <Button
                        aria-label={t('images.image_editor.rotate_image')}
                        onClick={handleRotate}
                        size="small"
                        title={t('images.image_editor.rotate_image')}
                        variant="contained"
                        color="primary"
                    >
                        <Rotate90DegreesCcwIcon />
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};
