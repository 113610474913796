import React, { useId, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { validateItemAge } from '@bladebinge/web-service-common/src/validations/validate-item-age';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { ListingTile } from '../../listing/ListingTile';
import { NoResults } from '../../atoms/NoResults';
import { PaginationByLinks } from '../../atoms/PaginationByLinksButtons';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { useMe } from '../../../context/me/me-context';
import { TradeFeatureHelpModal } from '../../atoms/TradeFeatureHelpModal';
import { useProfileListingsByProfileId } from '../../../hooks/react-query/use-profile-listings-by-profile-id';
import { GroupedListingsSearchAndSort } from '../GroupedListingsSearchAndSort';

export const OfferTradesSection = () => {
    const { t } = useTranslation();
    const ariaId = useId();
    const hasMounted = useHasMounted();
    const { activeProfileId: buyerProfileId, activePurchaseOfferListingIds } = useMe();
    const { isTradingEnabled } = useMakeAnOfferContext();
    const [manualListingPagingQuery, setManualListingPagingQuery] = useState<{
        limit: number;
        offset: number;
    }>({
        offset: 0,
        limit: 6
    });
    const [searchAndSortParams, setSearchAndSortParams] = useState({
        q: '',
        userListingSortByData: {
            sortBy: '',
            sortDirection: '',
            uiSelectorValue: ''
        }
    });

    const { addTradeToOffer, offerTradeListings, removeTradeFromOffer } = useMakeAnOfferContext();
    const isProd = process.env.ENV_CDK_LIFECYCLE === 'prod';

    const {
        data,
        error: loadListingsError,
        isLoading: loadingListings,
        refetch
    } = useProfileListingsByProfileId({
        userProfileId: buyerProfileId,
        hasMounted,
        query: {
            limit: `${manualListingPagingQuery.limit}`,
            offset: `${manualListingPagingQuery.offset}`
        },
        ...searchAndSortParams
    });

    const lastPageFetched = useMemo(() => (Array.isArray(data?.pages) ? data.pages.length - 1 : 0), [data]);
    const currentPageData = useMemo(
        () => data?.pages?.[lastPageFetched] ?? { result: [], _limit: 25, _total: 0, _links: undefined },
        [data, lastPageFetched]
    );
    const { _total = 0, result: currentPageItems = [], _links } = currentPageData;

    if (!buyerProfileId || !isTradingEnabled) {
        return null;
    }

    return (
        <Grid container spacing={0} size={12}>
            <Accordion sx={{ position: 'relative', width: '100%', pl: 0, ml: 0 }} disabled={!isTradingEnabled}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`offer-trade-${ariaId}-header`}
                    aria-controls={`offer-trade-${ariaId}-content`}
                >
                    <Typography variant="h6" component="div" sx={{ py: 0, my: 0 }}>
                        {t('common:purchase_offer.offer_a_trade')}
                    </Typography>
                    <TradeFeatureHelpModal />
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        justifyContent="space-around"
                        sx={{ borderTopColor: 'divider', borderTopStyle: 'solid', borderTopSize: '1px' }}
                        size={12}
                    >
                        <Grid size={12}>
                            <Typography variant="h6" component="div" sx={{ my: 1 }}>
                                {t('common:purchase_offer.choose_items_to_offer_for_trade')}
                            </Typography>
                        </Grid>
                        {buyerProfileId && (
                            <GroupedListingsSearchAndSort onChange={setSearchAndSortParams} groupId={buyerProfileId} />
                        )}
                        {loadListingsError && (
                            <Grid size={12}>
                                <Alert severity="error" onClose={() => refetch()}>
                                    {loadListingsError.message}
                                </Alert>
                            </Grid>
                        )}
                        {loadingListings && (
                            <Grid size={12}>
                                <LoadingIndicator />
                            </Grid>
                        )}
                        {currentPageItems.length > 0 ? (
                            <Grid container spacing={2} sx={{ mt: 2 }} size={12}>
                                {currentPageItems.map((listingGraph) => {
                                    const itemIsInOfferAlready = offerTradeListings.has(listingGraph.id);
                                    // Forego age checks in dev envs for testing
                                    const offerMeetsAgeRequirementForTrade = isProd
                                        ? validateItemAge({
                                              dateOrDateString: listingGraph.createdAt,
                                              comparisonUnit: 'days',
                                              operation: 'greaterThanOrEqualTo',
                                              unitQuantity: 1
                                          })
                                        : true;

                                    const offerPricingMeetsRequirementsForTrade = (
                                        listingGraph?.priceHistoryRecords ?? []
                                    ).reduce<boolean>(
                                        (isValid, { createdAt }) =>
                                            isValid &&
                                            validateItemAge({
                                                dateOrDateString: createdAt,
                                                comparisonUnit: 'days',
                                                operation: 'greaterThanOrEqualTo',
                                                unitQuantity: 1
                                            }),
                                        true
                                    );

                                    const itemSelectionDisabled =
                                        !listingGraph?.isTradeEnabled &&
                                        (!offerMeetsAgeRequirementForTrade ||
                                            !offerPricingMeetsRequirementsForTrade ||
                                            itemIsInOfferAlready ||
                                            activePurchaseOfferListingIds.has(listingGraph.id));

                                    return (
                                        <Grid
                                            key={listingGraph.id}
                                            sx={{
                                                opacity: itemSelectionDisabled ? 0.5 : 1
                                            }}
                                            size={{
                                                xs: 4,
                                                sm: 4,
                                                md: 2
                                            }}
                                        >
                                            <ListingTile
                                                iconActionButton={
                                                    <Fab
                                                        color={itemIsInOfferAlready ? 'default' : 'primary'}
                                                        disabled={itemSelectionDisabled}
                                                        onClick={() =>
                                                            itemIsInOfferAlready
                                                                ? removeTradeFromOffer(listingGraph)
                                                                : addTradeToOffer(listingGraph)
                                                        }
                                                        size="small"
                                                        sx={{
                                                            position: 'absolute',
                                                            zIndex: 1,
                                                            top: '-20px',
                                                            right: '-15px'
                                                        }}
                                                        title={
                                                            itemIsInOfferAlready
                                                                ? t('common:purchase_offer.remove_item_from_offer')
                                                                : t(
                                                                      'common:purchase_offer.remove_trade_item_from_offer'
                                                                  )
                                                        }
                                                    >
                                                        {itemIsInOfferAlready ? <DeleteIcon /> : <AddIcon />}
                                                    </Fab>
                                                }
                                                listing={listingGraph}
                                                showActionButtons={false}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <NoResults alternateMessage={t('common:purchase_offer.you_have_no_available_listings')} />
                        )}
                        <Grid sx={{ mb: 5 }} size={12}>
                            <PaginationByLinks
                                currentPageItemsCount={currentPageItems.length}
                                defaultLimit={5}
                                handleSetPagingParams={setManualListingPagingQuery}
                                links={_links}
                                total={_total}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
