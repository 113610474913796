import React from 'react';
import Grid from '@mui/material/Grid';
import { UsernameAutocompleteSearch } from './UsernameAutocompleteSearch';

export const FilterProfilesSearch = () => (
    <Grid container sx={{ m: 0 }} spacing={1} justifyContent="flex-start">
        <Grid size={10}>
            <UsernameAutocompleteSearch />
        </Grid>
    </Grid>
);
