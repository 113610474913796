import React from 'react';
import { useTranslation } from 'next-i18next';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useUserPreferences } from '../../context/user-preferences/user-preferences-context';
import { useHasMounted } from '../../hooks/use-has-mounted';

export const ThemeSwitcher = () => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const { themePreference, updateThemePreference } = useUserPreferences();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateThemePreference(event.target.checked ? 'dark' : 'light');
    };

    const isDarkMode = themePreference === 'dark';

    if (!hasMounted) {
        return null;
    }

    return (
        <FormGroup id="globalThemeSwitch">
            <Typography component="span">
                <Grid component="label" container alignItems="center">
                    <Grid>
                        <Typography
                            sx={{
                                color: isDarkMode ? 'divider' : 'common.white',
                                fontWeight: isDarkMode ? 'normal' : 'bold',
                                textDecoration: isDarkMode ? 'none' : 'underline'
                            }}
                            variant="body1"
                        >
                            {t('common:preferences.theme.light')}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Switch checked={isDarkMode} onChange={handleChange} size="small" />
                    </Grid>
                    <Grid>
                        <Typography
                            sx={{
                                color: isDarkMode ? 'common.white' : 'divider',
                                fontWeight: isDarkMode ? 'bold' : 'normal',
                                textDecoration: isDarkMode ? 'underline' : 'none'
                            }}
                            variant="body1"
                        >
                            {t('common:preferences.theme.dark')}
                        </Typography>
                    </Grid>
                </Grid>
            </Typography>
        </FormGroup>
    );
};
