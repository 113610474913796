import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { useMe } from '../../../context/me/me-context';

export const CompleteOfferButtons = ({
    size = 'large',
    textAlign
}: {
    readonly size?: 'small' | 'medium' | 'large';
    readonly textAlign?: 'right';
}) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const {
        clearOffer,
        hasAllRequirements,
        parentOfferId,
        purchaseOfferSuccess,
        purchaseOfferPending,
        purchaseOfferError,
        submitPurchaseOffer
    } = useMakeAnOfferContext();

    const confirmOffer = () => {
        if (!loggedInUserId) {
            return;
        }

        submitPurchaseOffer({
            offeredByUserId: loggedInUserId
        });
    };

    const cancelOffer = () => {
        clearOffer();
    };

    return (
        <Grid
            container
            spacing={1}
            sx={{ textAlign: 'right', mt: 0.5, mb: 1 }}
            justifyContent="flex-end"
            alignItems="flex-end"
            size={{
                xs: 12,
                sm: 6
            }}
        >
            <Grid size={6}>
                <Button
                    color="inherit"
                    fullWidth
                    onClick={cancelOffer}
                    size={size}
                    startIcon={<Cancel />}
                    variant="contained"
                >
                    {t('common:general.cancel')}
                </Button>
            </Grid>
            <Grid size={6}>
                <Button
                    fullWidth
                    color="primary"
                    data-testid="complete-offer-button"
                    disabled={Boolean(
                        !loggedInUserId ||
                            !hasAllRequirements ||
                            purchaseOfferPending ||
                            purchaseOfferSuccess ||
                            purchaseOfferError
                    )}
                    onClick={confirmOffer}
                    size={size}
                    startIcon={<Cancel />}
                    variant="contained"
                >
                    {parentOfferId ? t('common:purchase_offer.send_counter') : t('common:purchase_offer.send_offer')}
                </Button>
            </Grid>
        </Grid>
    );
};
