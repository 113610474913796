import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getGlobalStaticFeatureEnablement } from '@bladebinge/web-service-common/src/feature-flags/get-global-static-feature-enablement';
import { StyledFormLegalContentWrap } from '../../styled-shared';
import { useUserPreferences } from '../../../context/user-preferences/user-preferences-context';
import { TradesHowDoesItWorkText } from '../../atoms/TradesHowDoesItWorkText';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { useMe } from '../../../context/me/me-context';

export const RequiredPreferenceSettingsModal = () => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();
    const {
        persistedUserPreferences,
        persistedUserPreferencesFetched,
        updatingPersistedUserPreferences,
        updatePersistedUserPreferencesValues,
        updatePersistedUserPreferencesError
    } = useUserPreferences();

    const [enableOffersOptionChecked, setEnableOffersOptionChecked] = useState<boolean>(true);
    const [enableTradeOffersOptionChecked, setEnableTradeOffersOptionChecked] = useState<boolean>(true);
    const [showDetailedExplanation, setShowDetailedExplanation] = useState<boolean>(false);

    const isOfferingGloballyEnabled = getGlobalStaticFeatureEnablement('EnableOffers', loggedInUserId) === true;
    const isTradingGloballyEnabled = getGlobalStaticFeatureEnablement('EnableTrades', loggedInUserId) === true;

    const isOfferPreferenceSet = useMemo(() => {
        if (!persistedUserPreferencesFetched || !isOfferingGloballyEnabled) {
            return true;
        }

        if (
            persistedUserPreferences?.enableOffersOption === true ||
            persistedUserPreferences?.enableOffersOption === false
        ) {
            return true;
        }

        return false;
    }, [isOfferingGloballyEnabled, persistedUserPreferencesFetched, persistedUserPreferences]);

    const isTradingPreferenceSet = useMemo(() => {
        // if trading is disabled, we don't need to show the modal due to the trade preference
        if (!isTradingGloballyEnabled) {
            return true;
        }

        if (!persistedUserPreferencesFetched) {
            return true;
        }

        if (
            persistedUserPreferences?.enableTradeOffersOption === true ||
            persistedUserPreferences?.enableTradeOffersOption === false
        ) {
            return true;
        }

        return false;
    }, [isTradingGloballyEnabled, persistedUserPreferencesFetched, persistedUserPreferences]);

    const configureUserPreference = () => {
        updatePersistedUserPreferencesValues({
            preferences: {
                ...(isOfferingGloballyEnabled ? { enableOffersOption: enableOffersOptionChecked === true } : {}),
                ...(isTradingGloballyEnabled
                    ? { enableTradeOffersOption: enableTradeOffersOptionChecked === true }
                    : {})
            },
            subform: 'general'
        });
    };

    const handleEnableOffersCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        setEnableOffersOptionChecked(checked);
    };

    const handleEnableTradeOffersCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        setEnableTradeOffersOptionChecked(checked);
    };

    const shouldShowModal = !(isTradingPreferenceSet && isOfferPreferenceSet);

    if (!shouldShowModal) {
        // empty div with id is required for e2e tests to distinguish state
        return <div id="required_user_preferences_set"> </div>;
    }

    return (
        <Modal
            id="required_preference_settings_modal"
            aria-label={t('common:navigation.terms_of_service')}
            open={shouldShowModal}
            closeAfterTransition
            slots={{
                backdrop: Backdrop
            }}
            sx={{ overflow: 'scroll' }}
        >
            <StyledFormLegalContentWrap>
                {updatePersistedUserPreferencesError && (
                    <Grid container justifyContent="flex-start" sx={{ p: 1 }}>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 12,
                                lg: 12,
                                xl: 12
                            }}
                        >
                            <Alert severity="error">{updatePersistedUserPreferencesError?.message}</Alert>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1}>
                    <Grid size={12}>
                        <Alert severity="info">
                            <Grid container>
                                <Grid>
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        {t('common:user_preferences.required_preference_settings')}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 1 }}>
                                        {t('common:user_preferences.please_set_your_required_feature_preferences')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid sx={{ mt: 2 }} size={10}>
                            <Typography variant="h5" component="div">
                                {t('common:user_preferences.new_feature_alert_modal_heading')} -{' '}
                                {isTradingGloballyEnabled
                                    ? t('common:user_preferences.offers_with_trading_are_now_available_on_bladebinge')
                                    : t('common:user_preferences.offers_are_now_available_on_bladebinge')}
                            </Typography>
                            <Typography variant="caption">
                                {isTradingGloballyEnabled
                                    ? t(
                                          'common:user_preferences.trading_feature.offers_with_trading_feature_toggling_instruction'
                                      )
                                    : t('common:user_preferences.trading_feature.offers_feature_toggling_instruction')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {updatingPersistedUserPreferences && (
                        <Grid container spacing={1} justifyContent="center">
                            <Grid sx={{ textAlign: 'center' }} size={12}>
                                <LoadingIndicator />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} justifyContent="center">
                        {!isOfferPreferenceSet && (
                            <Grid sx={{ textAlign: 'center' }} size={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableOffersOptionChecked ?? false}
                                            inputProps={{
                                                className: 'set-enable-offers-preference-checkbox'
                                            }}
                                            onChange={handleEnableOffersCheck}
                                            name="enable_offers_peference_modal_checkbox"
                                        />
                                    }
                                    label={t('common:user_preferences.enable_offers_option')}
                                />
                            </Grid>
                        )}
                        {!isTradingPreferenceSet && (
                            <Grid sx={{ textAlign: 'center' }} size={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                (enableTradeOffersOptionChecked && enableOffersOptionChecked) ?? false
                                            }
                                            disabled={!enableOffersOptionChecked}
                                            inputProps={{
                                                className: 'set-enable-trade-preference-checkbox'
                                            }}
                                            onChange={handleEnableTradeOffersCheck}
                                            name="enable_trade_peference_modal_checkbox"
                                        />
                                    }
                                    label={t('common:user_preferences.enable_trade_offers_option')}
                                />
                                <Typography variant="caption" component="div" sx={{ mt: 0.5 }}>
                                    *{t('common:user_preferences.enabling_offers_required_for_trades')}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={1} sx={{ my: 1 }}>
                    <Grid sx={{ textAlign: 'center' }} size={12}>
                        <Button
                            color="primary"
                            id="save-required-trade-preference-button"
                            disabled={updatingPersistedUserPreferences}
                            onClick={configureUserPreference}
                            size="small"
                            type="submit"
                            variant="contained"
                        >
                            {t('common:user_preferences.save_preference_setting')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={1} sx={{ my: 1 }}>
                    <Grid size={12}>
                        <Grid sx={{ textAlign: 'right' }}>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => setShowDetailedExplanation(!showDetailedExplanation)}
                            >
                                {showDetailedExplanation
                                    ? t('common:user_preferences.hide_details')
                                    : t('common:user_preferences.trading_feature.how_does_it_work')}
                            </Button>
                        </Grid>
                        {showDetailedExplanation && <TradesHowDoesItWorkText />}
                    </Grid>
                </Grid>
            </StyledFormLegalContentWrap>
        </Modal>
    );
};
