import React, { useCallback } from 'react';
import { Image } from '@bladebinge/types';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import { uploadImages } from '../../../server/api-proxy/ui-mutation-fns/upload-images';
import { useMe } from '../../../context/me/me-context';
import { useMessageImagesContext } from '../../../context/image-uploads/message-images-context';
import { Pagination } from '../../atoms/Pagination';
import { NoResults } from '../../atoms/NoResults';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { ImageGridItem } from './ImageGridItem';
import { CroppedImageData } from './utils/types';
import { ImageUploader } from './ImageUploader';

export const SelectUserImagesForm = ({ onCloseForm }: { readonly onCloseForm: () => void }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { id: loggedInUserId } = useMe();
    const {
        detachedImageUploads,
        imageType,
        imageUploadCountLimit,
        setDetachedImageUploads,
        userImageUploadsCurrentPageItems,
        userImageUploadsLimit,
        userImageUploadsTotal,
        loadingUserImageUploads,
        loadingUserImageUploadsError,
        updateImagesPagingLimit
    } = useMessageImagesContext();

    const onCancel = () => {
        setDetachedImageUploads([]);
        onCloseForm();
    };

    const onClickImage = (image: Image) => {
        const updatedSelectedImages = [...detachedImageUploads];
        if (updatedSelectedImages.some((selectedImage) => selectedImage.id === image.id)) {
            updatedSelectedImages.splice(
                updatedSelectedImages.findIndex((selectedImage) => selectedImage.id === image.id),
                1
            );
        } else {
            updatedSelectedImages.push(image);
        }

        setDetachedImageUploads(updatedSelectedImages);
    };

    const isImageSelected = useCallback(
        (image: Image) => Boolean(detachedImageUploads.some((detachedImage) => detachedImage.id === image.id)),
        [detachedImageUploads]
    );

    const onUploadedImages = (addedImages: Image[]) => {
        const updatedDetachedImages = [...detachedImageUploads];
        addedImages.forEach((addedImage) => {
            if (!updatedDetachedImages.some((image) => image.id === addedImage.id)) {
                updatedDetachedImages.unshift(addedImage);
            }
        });
        setDetachedImageUploads(updatedDetachedImages.slice(0, imageUploadCountLimit));
    };

    const uploadImagesMutation = useMutation({
        mutationFn: uploadImages,
        onSuccess({ images: uploadedImages }: { images: Image[] }) {
            onUploadedImages(uploadedImages);
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.userImageUploads({
                    imageType,
                    loggedInUserId,
                    uploadLimit: userImageUploadsLimit
                }),
                type: 'active'
            });
        }
    });

    const uploadAddedImagesToApi = useCallback(
        (fileBlobs: CroppedImageData[]) => {
            uploadImagesMutation.mutate({
                fileBlobs,
                imageType,
                userId: loggedInUserId as string,
                uploadData: {
                    altText: '',
                    ownerId: loggedInUserId as string
                }
            });
        },
        [imageType, loggedInUserId, uploadImagesMutation]
    );

    const hasPreviousUploads = userImageUploadsCurrentPageItems.length > 0;

    const { error: uploadError, isPending: isUploadingImages, reset: resetUploadMutation } = uploadImagesMutation;

    const hasReachedUploadLimit = imageUploadCountLimit === detachedImageUploads.length;

    const previousUploadsContent = hasPreviousUploads ? (
        <>
            {!hasReachedUploadLimit && (
                <Grid container justifyContent="center" size={12}>
                    <Typography variant="body1" component="div">
                        {t('common:general.or')}
                    </Typography>
                    {uploadError && (
                        <Alert
                            sx={{
                                my: 0,
                                mx: 2
                            }}
                            onClose={resetUploadMutation}
                            severity="error"
                        >
                            {uploadError.message}
                        </Alert>
                    )}
                </Grid>
            )}
            <Grid container justifyContent="center" sx={{ mb: 2 }} size={12}>
                <Typography display="inline" variant="h6">
                    {t('common:images.select_from_previous_uploads')}
                </Typography>
            </Grid>
            {!hasPreviousUploads && (
                <NoResults
                    alternateActionComponent={
                        <Typography variant="body1">{t('common:images.no_previous_uploads_found')}</Typography>
                    }
                />
            )}
            <Grid container justifyContent="center" size={12}>
                {loadingUserImageUploadsError && (
                    <Grid size={12}>
                        <Alert
                            sx={{
                                my: 0,
                                mx: 2
                            }}
                            severity="error"
                        >
                            {loadingUserImageUploadsError.message}
                        </Alert>
                    </Grid>
                )}
                {userImageUploadsCurrentPageItems.map((image) => (
                    <ImageGridItem
                        key={image.id}
                        image={image}
                        onClick={() => onClickImage(image)}
                        selected={isImageSelected(image)}
                    />
                ))}
                {loadingUserImageUploads && <LoadingIndicator />}
            </Grid>
            {userImageUploadsTotal > userImageUploadsLimit && (
                <Grid container justifyContent="center">
                    <Grid>
                        <Pagination
                            result={userImageUploadsCurrentPageItems}
                            total={userImageUploadsTotal}
                            limitPreference={userImageUploadsLimit}
                            onUpdatePagingLimit={updateImagesPagingLimit}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    ) : null;

    return (
        <Grid container justifyContent="center">
            {!hasReachedUploadLimit && (
                <Grid size={12}>
                    <Typography variant="h6" sx={{ textAlign: 'center', mb: 1 }}>
                        {t('common:images.upload_images')}
                    </Typography>
                    <ImageUploader
                        clearOnSave
                        isUploadingImages={isUploadingImages}
                        filesLimit={imageUploadCountLimit}
                        onSave={uploadAddedImagesToApi}
                        remainingFilesCount={imageUploadCountLimit - detachedImageUploads.length}
                    />
                </Grid>
            )}
            <Grid container spacing={1} justifyContent="flex-end" sx={{ my: 1 }} size={11}>
                {/* <DeatchedImageUploadsViewer /> */}
                <Grid>
                    <Button
                        aria-label={t('common:general.cancel')}
                        color="inherit"
                        onClick={onCancel}
                        startIcon={<Cancel />}
                        sx={{ mt: 0.5 }}
                        title={t('common:general.cancel')}
                        variant="contained"
                    >
                        {t('common:general.cancel')}
                    </Button>
                </Grid>
                <Grid>
                    <Button
                        aria-label={t('common:images.confirm_selected_images', { count: detachedImageUploads.length })}
                        disabled={detachedImageUploads.length === 0}
                        color="primary"
                        onClick={onCloseForm}
                        sx={{ mt: 0.5 }}
                        variant="contained"
                    >
                        {t('common:images.confirm_selected_images', { count: detachedImageUploads.length })}
                    </Button>
                </Grid>
            </Grid>
            {previousUploadsContent}
        </Grid>
    );
};
