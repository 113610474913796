import React, { useId, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';
import { PurchaseOfferStandaloneMessageBox } from './PurchaseOfferStandaloneMessage';

export const PurchaseOfferAddMessageForm = ({ allowActiveEdits }: { readonly allowActiveEdits: boolean }) => {
    const { t } = useTranslation();
    const ariaId = useId();
    const { offerMessageData, setOfferMessageData } = useMakeAnOfferContext();

    const updateMessageData = useMemo(
        () => (offerMessageData === null ? undefined : setOfferMessageData),
        [offerMessageData, setOfferMessageData]
    );

    const defaultMessage = useMemo(() => offerMessageData?.messageBody, [offerMessageData]);

    return (
        <Grid container sx={{ my: 1 }} size={12}>
            <Accordion sx={{ width: '100%', pl: 0, ml: 0 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`offer-add-message-form=${ariaId}-content`}
                    id={`offer-add-message-form=${ariaId}-header`}
                >
                    <Typography variant="h6" component="div" sx={{ py: 0, my: 0 }}>
                        {t('common:purchase_offer.add_message')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                    <Grid size={12}>
                        <PurchaseOfferStandaloneMessageBox
                            defaultMessage={defaultMessage}
                            onSubmit={setOfferMessageData}
                            onUpdate={updateMessageData}
                            readonly={!allowActiveEdits}
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
