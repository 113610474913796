import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import 'react-phone-number-input/style.css';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { uiCacheKeyBuilderMap } from '@bladebinge/web-service-common/src/utils/cache-key-builder';
import type { Address, AddressFormState, OwnedAddressPostJson } from '@bladebinge/types';
import { useMe } from '../../context/me/me-context';
import { StyledFormMessagingWrapper } from '../styled-shared';
import {
    createAddress,
    createUserProfileAddress,
    updateUserProfileAddress
} from '../../server/api-proxy/ui-mutation-fns/addresses';
import { AddressForm } from './AddressForm';
import { AddressValidationOverrideForm } from './AddressValidationOverrideForm';

interface AddressFormWraperProps {
    readonly addressToEdit?: Address;
    readonly cancelButtonText?: string;
    readonly className?: string;
    readonly formAction?: 'create' | 'createFromUserProfile' | 'update';
    readonly onCancel?: (confirmedAddressId?: string) => void;
    readonly submitButtonText?: string;
    readonly submitCallback: (confirmedAddressId?: string) => void;
}

export const AddressFormWrapper = ({
    addressToEdit,
    cancelButtonText,
    className,
    formAction = 'create',
    onCancel,
    submitButtonText,
    submitCallback
}: AddressFormWraperProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [latestSubmissionData, setLatestSubmissionData] = useState<AddressFormState | null>(null);
    const { activeProfileId: userProfileId, id: loggedInUserId } = useMe();

    const createAddressMutation = useMutation({
        mutationFn: createAddress,
        onSuccess({ id: createdProfileAddressId }: Address) {
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.userProfileAddressBook({ loggedInUserId, userProfileId }),
                type: 'all'
            });

            if (createdProfileAddressId) {
                submitCallback(createdProfileAddressId);
            }
        }
    });

    const createUserProfileAddressMutation = useMutation({
        mutationFn: createUserProfileAddress,
        onSuccess({ id: createdProfileAddressId }: Address) {
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.userProfileAddressBook({ loggedInUserId, userProfileId }),
                type: 'all'
            });

            if (createdProfileAddressId) {
                submitCallback(createdProfileAddressId);
            }
        }
    });

    const updateAddressMutation = useMutation({
        mutationFn: updateUserProfileAddress,
        onSuccess(_data, { updatedAddress: { id: updatedAddressId } }) {
            queryClient.invalidateQueries({
                queryKey: uiCacheKeyBuilderMap.userProfileAddressBook({ loggedInUserId, userProfileId }),
                type: 'all'
            });

            if (updatingAddressId) {
                submitCallback(updatingAddressId);
            }
        }
    });

    const createError =
        formAction === 'create'
            ? createAddressMutation?.error?.message
            : createUserProfileAddressMutation?.error?.message;
    const isCreating =
        formAction === 'create' ? createAddressMutation?.isPending : createUserProfileAddressMutation?.isPending;
    const { id: updatingAddressId } = addressToEdit ?? {};

    const handleAddressFormSubmission = (formData: AddressFormState, overrideValidation = false) => {
        setLatestSubmissionData(formData);

        if (formAction === 'create' && !createAddressMutation.isPending) {
            createAddressMutation.mutate({
                ...formData,
                ownerId: loggedInUserId as string,
                ...(overrideValidation ? { forceWithFailedValidation: true } : {})
            });
        }

        if (formAction === 'createFromUserProfile' && userProfileId && !createUserProfileAddressMutation.isPending) {
            createUserProfileAddressMutation.mutate({
                address: formData as OwnedAddressPostJson,
                userProfileId,
                ...(overrideValidation ? { forceWithFailedValidation: true } : {})
            });
        }

        if (formAction === 'update' && userProfileId) {
            updateAddressMutation.mutate({
                updatedAddress: {
                    ...formData,
                    id: updatingAddressId,
                    ownerId: loggedInUserId as string
                },
                userProfileId
            });
        }
    };

    const handleOverrideValidationAddressSubmission = () => {
        if (!latestSubmissionData) {
            return;
        }

        handleAddressFormSubmission(
            {
                ...latestSubmissionData,
                forceWithFailedValidation: true
            },
            true
        );
    };

    const submitActionText = submitButtonText ? submitButtonText : t('common:address_form.save_address');
    const cancelErrorHandler = () => {
        formAction === 'create' ? createAddressMutation.reset() : createUserProfileAddressMutation.reset();
    };

    const cancelOverrideHandler = () => {
        setLatestSubmissionData(null);
        cancelErrorHandler();
    };

    return (
        <Grid container {...(className ? { className } : {})}>
            {createError && (
                <Grid size={12}>
                    <StyledFormMessagingWrapper>
                        <Alert onClose={cancelErrorHandler} severity="error" sx={{ whiteSpace: 'break-spaces' }}>
                            {createError as string}
                            <AddressValidationOverrideForm
                                onCancel={cancelOverrideHandler}
                                onSubmit={handleOverrideValidationAddressSubmission}
                            />
                        </Alert>
                    </StyledFormMessagingWrapper>
                </Grid>
            )}
            <Grid size={12}>
                <AddressForm
                    addressToEdit={addressToEdit}
                    cancelButtonText={cancelButtonText}
                    isProcessing={isCreating}
                    onCancel={onCancel}
                    onSubmit={handleAddressFormSubmission}
                    showDefaultCheckboxes={Boolean(loggedInUserId)}
                    submitButtonText={submitActionText}
                />
            </Grid>
        </Grid>
    );
};
