import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { useMe } from '../../../context/me/me-context';
import { useUserInterfaceNotificationsContext } from '../../../context/user-interface-notifications/user-interface-notifications-context';
import { UserInterfaceNotificationsMenu } from './UserInterfaceNotificationsMenu';

export const UserNotificationsNavButton = ({ className = '' }: { readonly className?: string }) => {
    const { id: loggedInUserId } = useMe();
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const { isMenuOpen, countsData, toggleMenu } = useUserInterfaceNotificationsContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [animationClass, setAnimationClass] = useState<string>(''); // pulse-animation, css is global.css

    const buttonTitleTranslationKey = useMemo(() => {
        if (countsData?.TOTAL === 0) {
            return 'common:navigation.notifications_menu_none_active';
        }

        return isMenuOpen ? 'common:navigation.close_notifications_menu' : 'common:navigation.open_notifications_menu';
    }, [isMenuOpen, countsData]);

    const noneToDisplay = countsData?.TOTAL === 0;

    useEffect(() => {
        if (countsData?.TOTAL > 0) {
            setAnimationClass('pulse-animation');
            const timer = setTimeout(() => setAnimationClass(''), 3000);
            // clear timeout on unmount
            return () => clearTimeout(timer);
        }

        // noop if countsData does not meet conditions
        return () => {};
    }, [countsData?.TOTAL, setAnimationClass]);

    if (!loggedInUserId) {
        return null;
    }

    const handleMenuToggleClick = (event: React.MouseEvent<HTMLElement>) => {
        const updateIsOpen = !isMenuOpen;
        const anchorElValue = updateIsOpen ? event.currentTarget : null;
        setAnchorEl(anchorElValue);
        toggleMenu();
    };

    return (
        <Grid
            className={className}
            sx={{
                mr: 0.25,
                mt: 0.5
            }}
        >
            <IconButton
                disabled={noneToDisplay}
                onClick={handleMenuToggleClick}
                sx={{ opacity: noneToDisplay ? 0.25 : 1 }}
                title={t(buttonTitleTranslationKey)}
            >
                <Badge
                    badgeContent={hasMounted ? countsData?.TOTAL : 0}
                    className={animationClass}
                    color="primary"
                    overlap="rectangular"
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                    }}
                >
                    <NotificationsIcon color="secondary" />
                </Badge>
            </IconButton>
            <UserInterfaceNotificationsMenu anchorEl={anchorEl} />
        </Grid>
    );
};
