import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmDialog = ({
    children,
    closeOnConfirm = true,
    confirmButtonText,
    denyButtonText,
    confirmDisabled = false,
    onConfirm,
    open,
    setOpen,
    title,
    titleIcon
}: {
    readonly children: React.ReactNode;
    readonly closeOnConfirm?: boolean;
    readonly confirmButtonText?: string;
    readonly confirmDisabled?: boolean;
    readonly denyButtonText?: string;
    readonly onConfirm: () => void | Promise<void>;
    readonly open: boolean;
    readonly setOpen: (isOpen: boolean) => void;
    readonly title: string;
    readonly titleIcon?: React.ReactNode;
}) => {
    const { t } = useTranslation();
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">
                {titleIcon && titleIcon}
                {title}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {!isConfirmed && (
                <DialogActions>
                    <Button
                        color="secondary"
                        className="deny-dialog-btn"
                        onClick={() => setOpen(false)}
                        variant="contained"
                    >
                        {denyButtonText ?? t('common:general.no')}
                    </Button>
                    <Button
                        color="primary"
                        className="confirm-dialog-btn"
                        disabled={confirmDisabled}
                        onClick={() => {
                            onConfirm();
                            closeOnConfirm ? setOpen(false) : setIsConfirmed(true);
                        }}
                        variant="contained"
                    >
                        {confirmButtonText ?? t('common:general.yes')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};
