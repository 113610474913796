import React from 'react';
import { default as NextImage } from 'next/image';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';

export const UIErrorPage = () => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} sx={{ p: 1.5 }}>
            <Grid sx={{ textAlign: 'center', mt: 1 }} justifyContent="center" size={12}>
                <NextImage alt="" width="425" height="425" priority src={getCdnUrl('bladebinge_ui_error1.png')} />
            </Grid>
            <Grid container sx={{ textAlign: 'center', mt: 1 }} justifyContent="center" size={12}>
                <Grid size={8}>
                    <Typography variant="h4" component="div">
                        {t('common:general.error_page_heading')}
                    </Typography>
                    <Typography variant="body1" component="div">
                        {t('common:general.error_page_instructions')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
