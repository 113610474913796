import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { useUserInterfaceNotificationsContext } from '../../../context/user-interface-notifications/user-interface-notifications-context';
import { StyledModalCancelIcon, StyledNarrowModalPaper } from '../../styled-shared';
import { ConfirmDialog } from '../../atoms/ConfirmDialog';
import { NotificationMenuListItem } from './NotificationMenuListItem';

export const UserInterfaceNotificationsMenu = ({ anchorEl }: { readonly anchorEl: HTMLElement | null }) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const [confirmDismissalDialogOpen, setConfirmDismissalDialogOpen] = useState(false);

    const { countsData, handleBulkDismissal, isDismissingNotifications, isMenuOpen, sortedCountsData, toggleMenu } =
        useUserInterfaceNotificationsContext();

    const handleDismissalClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        setConfirmDismissalDialogOpen(true);
    };

    if (!isMenuOpen || anchorEl === null || !hasMounted) {
        return null;
    }

    return (
        <Popover
            anchorEl={anchorEl}
            elevation={3}
            id="customized-menu"
            keepMounted
            open={isMenuOpen}
            onClose={() => toggleMenu()}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            sx={{
                top: '40px',
                left: {
                    xs: 0,
                    sm: 0,
                    md: '40px'
                }
            }}
        >
            <StyledNarrowModalPaper>
                <StyledModalCancelIcon onClick={toggleMenu} />
                <Grid container sx={{ pt: 0.25 }}>
                    <Grid size={12}>
                        <Button
                            aria-label={t('common:notifications_menu.dismiss_all')}
                            className="dismiss-all-notifications-btn"
                            disabled={isDismissingNotifications}
                            onClick={handleDismissalClick}
                            size="small"
                            title={t('common:notifications_menu.dismiss_all')}
                            variant="outlined"
                        >
                            <Typography variant="body2">
                                <RemoveCircleOutlineIcon sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                                {t('common:notifications_menu.dismiss_all')}
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid size={12}>
                        <List>
                            {sortedCountsData.map(([displayType, count]) => (
                                <NotificationMenuListItem key={displayType} displayType={displayType} count={count} />
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </StyledNarrowModalPaper>
            <ConfirmDialog
                confirmButtonText={t('common:general.confirm')}
                denyButtonText={t('common:general.cancel')}
                onConfirm={() => handleBulkDismissal('DISMISS_ALL')}
                open={confirmDismissalDialogOpen}
                setOpen={setConfirmDismissalDialogOpen}
                title={t('common:notifications_menu.dismiss_all_notifications_title')}
                titleIcon={<RemoveCircleOutlineIcon color="error" sx={{ mr: 1, position: 'relative', top: '4px' }} />}
            >
                <Typography variant="body1">
                    {t('common:notifications_menu.dismiss_notifications_count', { count: countsData.TOTAL })}
                </Typography>
            </ConfirmDialog>
        </Popover>
    );
};
