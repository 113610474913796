import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber, default as ReactPhoneNumberInput } from 'react-phone-number-input';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import type { Address, AddressFormState, CountryOption, StateOption } from '@bladebinge/types';
import { StyledFullWidthForm, StyledHookFormErrorMessage } from '../styled-shared';
import { CustomPhoneNumber } from './CustomPhoneNumber';
import { CountryRegionSelector } from './CountryRegionSelector';

export const AddressForm = ({
    addressToEdit,
    cancelButtonText,
    isProcessing = false,
    onCancel,
    submitButtonText,
    onSubmit,
    showDefaultCheckboxes
}: {
    readonly addressToEdit?: Address;
    readonly cancelButtonText?: string;
    readonly isProcessing?: boolean;
    readonly onCancel?: (confirmedAddressId?: string) => void;
    readonly onSubmit: (formData: AddressFormState) => void | Promise<void>;
    readonly showDefaultCheckboxes?: boolean;
    readonly submitButtonText?: string;
}) => {
    const { t } = useTranslation();

    const [phoneCountryShortCode, setPhoneCountryShortCode] = useState<string>('US');

    const [isDefaultBillingChecked, setIsDefaultBillingChecked] = useState<boolean>(
        addressToEdit?.isPrimaryBilling ?? false
    );
    const [isDefaultShippingChecked, setIsDefaultShippingChecked] = useState<boolean>(
        addressToEdit?.isPrimaryShipping ?? false
    );
    const { id: updatingAddressId, ...restOfEditAddress } = addressToEdit ?? {};

    const {
        control,
        formState: { errors, isValid },
        getValues,
        handleSubmit,
        register,
        setValue
    } = useForm<AddressFormState>({
        defaultValues: {
            city: '',
            company: '',
            country: 'United States',
            isPrimaryBilling: false,
            isPrimaryShipping: false,
            name: '',
            phone: '',
            state: '',
            street1: '',
            street2: '',
            street3: '',
            zip: '',
            ...restOfEditAddress
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });

    const handleFormSubmit = async (formData: AddressFormState) => {
        if (isValid) {
            await onSubmit(formData);
        }
    };

    const handleCountryChange = (countryOption: CountryOption) => {
        const { name, shortCode } = countryOption;
        if (shortCode && phoneCountryShortCode !== shortCode) {
            setPhoneCountryShortCode(shortCode);
        }

        setValue('country', name);
    };

    const handleDefaultBillingAddressCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        setValue('isPrimaryBilling', checked, { shouldDirty: true });
        setIsDefaultBillingChecked(checked);
    };

    const handleDefaultShippingAddressCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        setValue('isPrimaryShipping', checked, { shouldDirty: true });
        setIsDefaultShippingChecked(checked);
    };

    const handleRegionChange = (stateOption: StateOption) => {
        const { name } = stateOption;
        setValue('state', name, { shouldDirty: true });
    };

    const submitActionText = submitButtonText ? submitButtonText : t('common:address_form.save_address');

    // hook form material UI register calls
    const { ref: nameRef, ...nameRest } = register('name', { required: true });
    const { ref: companyRef, ...companyRest } = register('company', { required: false });
    const { ref: street1Ref, ...street1Rest } = register('street1', { required: true });
    const { ref: street2Ref, ...street2Rest } = register('street2', { required: false });
    const { ref: street3Ref, ...street3Rest } = register('street3', { required: false });
    const { ref: cityRef, ...cityRest } = register('city', { required: true });
    const { ref: zipRef, ...zipRest } = register('zip', { required: true });
    const { ref: isPrimaryBillingRef, ...isPrimaryBillingRest } = register('isPrimaryBilling');
    const { ref: isPrimaryShippingRef, ...isPrimaryShippingRest } = register('isPrimaryShipping');
    const { ref: phoneRef, ...phoneRest } = register('phone', {
        required: true,
        validate: (phn?: string) => (phn ? isValidPhoneNumber(phn) : false)
    });

    return (
        <StyledFullWidthForm data-testid="create_address_form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.name')}
                    className={errors.name ? 'input-error' : ''}
                    fullWidth
                    id="name"
                    inputRef={nameRef}
                    label={t('common:address_form.name')}
                    placeholder={t('common:address_form.name')}
                    margin="normal"
                    required
                    variant="outlined"
                    {...nameRest}
                />
                <StyledHookFormErrorMessage
                    as="div"
                    errors={errors}
                    message={t('common:address_form.errors.name_error')}
                    name="name"
                />
            </Grid>
            <Grid container>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.company')}
                    className={errors.company ? 'input-error' : ''}
                    fullWidth
                    id="company"
                    inputRef={companyRef}
                    label={t('common:address_form.company')}
                    placeholder={t('common:address_form.company')}
                    margin="normal"
                    variant="outlined"
                    {...companyRest}
                />
            </Grid>
            <Grid container>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.street1')}
                    className={errors.street1 ? 'input-error' : ''}
                    fullWidth
                    id="street1"
                    inputRef={street1Ref}
                    label={t('common:address_form.street_line_one')}
                    placeholder={t('common:address_form.street_line_one')}
                    margin="normal"
                    required
                    variant="outlined"
                    {...street1Rest}
                    onChange={(e) => {
                        // overriding at least one field so that react-hookf-form sees dirty state after chrome autofill
                        setValue('street1', e.target.value, {
                            shouldDirty: true,
                            shouldValidate: true
                        });
                    }}
                />
                <StyledHookFormErrorMessage
                    as="div"
                    errors={errors}
                    message={t('common:address_form.errors.invalid_street_line_one')}
                    name="street1"
                />
            </Grid>
            <Grid container>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.street2')}
                    className={errors.street2 ? 'input-error' : ''}
                    fullWidth
                    id="street2"
                    inputRef={street2Ref}
                    label={t('common:address_form.street_line_two')}
                    placeholder={t('common:address_form.street_line_two')}
                    margin="normal"
                    variant="outlined"
                    {...street2Rest}
                />
            </Grid>
            <Grid container>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    autoComplete={t('common:address_form.autocomplete_labels.street3')}
                    className={errors.street3 ? 'input-error' : ''}
                    fullWidth
                    id="street3"
                    inputRef={street3Ref}
                    label={t('common:address_form.street_line_three')}
                    placeholder={t('common:address_form.street_line_three')}
                    margin="normal"
                    variant="outlined"
                    {...street3Rest}
                />
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid
                    size={{
                        sm: 6,
                        xs: 6
                    }}
                >
                    <Grid container direction="column">
                        <Grid>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={errors.city ? 'input-error' : ''}
                                id="city"
                                inputRef={cityRef}
                                label={t('common:address_form.city')}
                                margin="normal"
                                placeholder={t('common:address_form.city')}
                                required
                                variant="outlined"
                                {...cityRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:address_form.errors.invalid_city')}
                                name="city"
                            />
                        </Grid>
                        <Grid>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={errors.zip ? 'input-error' : ''}
                                id="zip"
                                inputRef={zipRef}
                                label={t('common:address_form.zip')}
                                margin="normal"
                                placeholder={t('common:address_form.zip')}
                                required
                                variant="outlined"
                                {...zipRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:address_form.errors.invalid_zip')}
                                name="zip"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    size={{
                        sm: 5,
                        xs: 5
                    }}
                >
                    <CountryRegionSelector
                        errors={errors}
                        handleChangeCountry={handleCountryChange}
                        handleChangeRegion={handleRegionChange}
                    />
                    <input id="country" {...register('country', { required: true })} required type="hidden" />
                    <input id="state" {...register('state', { required: true })} required type="hidden" />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid
                    size={{
                        xs: 9,
                        sm: 9
                    }}
                >
                    <Controller
                        control={control}
                        render={({ field: { value, ...restOfField } }) => (
                            <ReactPhoneNumberInput
                                autoComplete={t('common:address_form.autocomplete_labels.phone')}
                                className={errors.phone ? 'input-error' : ''}
                                // @ts-expect-error
                                defaultCountry={phoneCountryShortCode}
                                id="phone"
                                inputComponent={CustomPhoneNumber}
                                placeholder={t('common:address_form.phone')}
                                required
                                value={getValues('phone') || ''}
                                {...restOfField}
                                ref={phoneRef}
                            />
                        )}
                        {...phoneRest}
                        name="phone"
                    />
                    <StyledHookFormErrorMessage
                        as="div"
                        errors={errors}
                        message={t('common:address_form.errors.invalid_phone')}
                        name="phone"
                    />
                </Grid>
            </Grid>
            {showDefaultCheckboxes && (
                <>
                    <Grid container justifyContent="center">
                        <Grid>
                            <FormControlLabel
                                checked={isDefaultBillingChecked}
                                control={<Checkbox />}
                                inputRef={isPrimaryBillingRef}
                                label={t('common:address_form.is_default_billing')}
                                {...isPrimaryBillingRest}
                                onChange={handleDefaultBillingAddressCheck}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid>
                            <FormControlLabel
                                checked={isDefaultShippingChecked}
                                control={<Checkbox />}
                                inputRef={isPrimaryShippingRef}
                                label={t('common:address_form.is_default_shipping')}
                                {...isPrimaryShippingRest}
                                onChange={handleDefaultShippingAddressCheck}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container alignItems="center" spacing={onCancel ? 1 : 0} sx={{ mt: 2, mx: 0, mb: 3 }}>
                {onCancel && (
                    <Grid
                        size={{
                            xs: 6,
                            sm: 6
                        }}
                    >
                        <Button
                            color="secondary"
                            disabled={isProcessing}
                            fullWidth
                            onClick={() => onCancel(updatingAddressId)}
                            size="large"
                            variant="contained"
                        >
                            {cancelButtonText}
                        </Button>
                    </Grid>
                )}
                <Grid
                    size={{
                        xs: onCancel ? 6 : 12,
                        sm: onCancel ? 6 : 12
                    }}
                >
                    <Button
                        color="primary"
                        disabled={isProcessing}
                        fullWidth
                        id="submit_address_form_btn"
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {submitActionText}
                    </Button>
                </Grid>
            </Grid>
        </StyledFullWidthForm>
    );
};
