import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useDropzone } from 'react-dropzone';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Cancel from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';
import { StyledMarginYDiv } from '../../styled-shared';
import { readableBytes } from './utils/readable-bytes';
import { CroppedImageData } from './utils/types';
import { getBase64Strings } from './utils/get-base-64-strings';
import { getCroppedSquareImageBlobData } from './utils/get-cropped-square-image-blob-data';
import { CropGrid } from './CropGrid';
import type { ImageDataWithCropInfo, ImageUpload } from './types';

interface ImageUploaderProps {
    readonly clearOnSave?: boolean;
    readonly isUploadingImages?: boolean;
    readonly filesLimit: number;
    readonly onCancel?: () => void;
    readonly onSave: (cropBlobs: CroppedImageData[]) => void | Promise<void>;
    readonly remainingFilesCount?: number;
}

const truncateFilename = (fileName: string, strLen: number): string => {
    if (fileName.length <= strLen) {
        return fileName;
    }

    const separator = '...';
    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return `${fileName.slice(0, Math.max(0, frontChars))}${separator}${fileName.slice(
        Math.max(0, fileName.length - backChars)
    )}`;
};

export const ImageUploader = ({
    clearOnSave = false,
    isUploadingImages,
    filesLimit = 4,
    onCancel,
    onSave,
    remainingFilesCount
}: ImageUploaderProps) => {
    const { t } = useTranslation();
    const hasMounted = useHasMounted();
    const [isProcessingImageCrops, setIsProcessingImageCrops] = useState<boolean>(false);
    const [imageUploadsRotatedData, setImageUploadsRotatedData] = useState<ImageUpload[]>([]);
    const [remainingUploadsAvailableCount, setRemainingUploadsAvailableCount] = useState<number>(
        remainingFilesCount ?? filesLimit
    );

    const onDrop = async (acceptedFiles: File[]) => {
        const rotatedImageData = await getBase64Strings(acceptedFiles);
        setImageUploadsRotatedData(rotatedImageData);
    };

    const allowedTypesString = '.png, .jpeg, .jpg, .webp, .gif';
    const sizeLimitBytes = 7_500_000;
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles = [],
        fileRejections = []
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/webp': ['.webp'],
            'image/gif': ['.gif']
        },
        maxFiles: filesLimit,
        maxSize: sizeLimitBytes, // 7.5MB max file sizeLimit
        noDragEventsBubbling: true,
        onDrop
    });

    // re-render hack to clear error message data after save
    const [key, setKey] = useState<number>(0);

    useEffect(() => {
        if (!hasMounted) {
            return;
        }

        setRemainingUploadsAvailableCount(remainingUploadsAvailableCount ?? filesLimit);
    }, [filesLimit, hasMounted, remainingUploadsAvailableCount]);

    const getDropRejectMessage = (rejectedFile: File) =>
        t('common:images.images_form.errors.drop_rejected', {
            fileName: truncateFilename(rejectedFile?.name, 20),
            allowedTypes: allowedTypesString,
            sizeLimit: readableBytes(sizeLimitBytes)
        });

    const handleCancelCrop = () => {
        setImageUploadsRotatedData([]);
    };

    const onChange = async () => {
        // @ts-expect-error
        const rotatedImageData = await getBase64Strings(acceptedFiles ?? []);
        setImageUploadsRotatedData(rotatedImageData);
    };

    const saveCroppedImages = async (imageDataWithCrops: ImageDataWithCropInfo[]) => {
        setIsProcessingImageCrops(true);
        const cropBlobs = await Promise.all(
            imageDataWithCrops.map((imageDataWithCrop) => getCroppedSquareImageBlobData(imageDataWithCrop))
        );
        setIsProcessingImageCrops(false);
        onSave(cropBlobs);

        if (clearOnSave) {
            setImageUploadsRotatedData([]);
            // re-render hack to reset error message related internal state
            window.setTimeout(() => {
                setKey(key + 1);
            }, 0);
        }
    };

    const uploader = (
        <Paper
            key={key}
            {...getRootProps()}
            sx={{
                p: 2,
                textAlign: 'center',
                border: '2px dashed',
                borderColor: isDragActive ? 'primary.main' : 'grey.400',
                backgroundColor: isDragActive ? 'grey.100' : 'inherit',
                cursor: 'pointer',
                minHeight: '200px'
            }}
        >
            <input {...getInputProps()} />
            <Typography variant="body1">
                {t('common:images.image_editor.drag_images_here_or_click', {
                    count: remainingUploadsAvailableCount
                })}
            </Typography>
            <CloudUploadIcon data-testid="file-uploader-icon" sx={{ fontSize: '3rem', color: 'contrastText' }} />
            {fileRejections.length > 0 &&
                fileRejections.map((fileRejection) => (
                    <Alert key={fileRejection?.file?.name} severity="error">
                        <Typography variant="body2">{getDropRejectMessage(fileRejection?.file)}</Typography>
                    </Alert>
                ))}
        </Paper>
    );

    const hasUploads = imageUploadsRotatedData.length > 0;
    const hasImages = acceptedFiles && acceptedFiles.length > 0;

    return (
        <Grid container spacing={1} justifyContent="center">
            <Grid
                size={{
                    xs: 11,
                    sm: 11,
                    md: 11,
                    lg: 11,
                    xl: 11
                }}
            >
                <Alert icon={<AspectRatioIcon />} severity="info">
                    {t('common:images.upload_in_square_mode')}
                </Alert>
                {uploader}
                {isProcessingImageCrops ||
                    (isUploadingImages && (
                        <Grid>
                            <LoadingIndicator />
                        </Grid>
                    ))}
                <StyledMarginYDiv>
                    {hasUploads && hasImages && !isProcessingImageCrops && (
                        <CropGrid
                            rawImageFiles={imageUploadsRotatedData}
                            onCancel={handleCancelCrop}
                            onSave={saveCroppedImages}
                        />
                    )}
                </StyledMarginYDiv>
                <Grid container justifyContent="flex-end" spacing={1} sx={{ my: 1 }}>
                    {onCancel && (
                        <Grid>
                            <Button
                                aria-label={t('common:avatar_editor.cancel')}
                                color="inherit"
                                onClick={onCancel}
                                startIcon={<Cancel />}
                                sx={{ mt: 0.5 }}
                                title={t('common:avatar_editor.cancel')}
                                variant="outlined"
                            >
                                {t('common:avatar_editor.cancel')}
                            </Button>
                        </Grid>
                    )}
                    {!isUploadingImages && !hasUploads && hasImages && (
                        <Grid>
                            <Button onClick={onChange} variant="contained" color="primary">
                                {t('common:images.image_editor.next_step')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
