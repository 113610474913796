import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { StyledBulletedList, StyledFormLegalContentWrap, StyledModalCancelIcon } from '../styled-shared';

export const SearchHelpModal = ({ className }: { readonly className?: string }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = () => setIsModalOpen((previousOpenState) => !previousOpenState);

    return (
        <Box className={className}>
            <Button
                aria-label={t('common:search_help.search_help')}
                onClick={toggleModal}
                title={t('common:search_help.search_help')}
                color="inherit"
                sx={{
                    fontSize: 'inherit',
                    px: 0,
                    color: 'text.primary',
                    width: 'auto',
                    minWidth: 'auto'
                }}
                size="small"
                variant="text"
            >
                <QuestionMarkIcon sx={{ fontSize: 'inherit', mx: 0.25, px: 0, color: 'divider' }} color="inherit" />
            </Button>
            <Modal
                aria-label={t('common:search_help.search_help')}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                slots={{
                    backdrop: Backdrop
                }}
                sx={{ overflow: 'scroll' }}
            >
                <StyledFormLegalContentWrap>
                    <StyledModalCancelIcon onClick={() => setIsModalOpen(false)} />
                    <Grid container spacing={1} justifyContent="center">
                        <Paper sx={{ m: 1.5, p: 1.5 }}>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 12
                                }}
                            >
                                <Typography variant="h6">{t('common:search_help.search_help')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid container sx={{ pl: 2, pt: 0.5 }} size={12}>
                                <Typography variant="body1" sx={{ my: 1.5 }}>
                                    {t('common:search_help.search_help_overview')}
                                </Typography>
                                <Grid sx={{ pl: 2, pt: 0.5 }} size={12}>
                                    <StyledBulletedList>
                                        <li>
                                            <Typography variant="body2">
                                                {t('common:search_help.quoted_phrases')}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                {t('common:search_help.excluded_words')}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body2">
                                                {t('common:search_help.fine_tune_with_filters')}
                                            </Typography>
                                        </li>
                                    </StyledBulletedList>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </StyledFormLegalContentWrap>
            </Modal>
        </Box>
    );
};
