// Adapted from npm exif-rotate-js/
import * as ExifReader from 'exifreader';
import { ImageUpload } from '../types';

interface TOptions {
    maxSize?: number;
    type?: 'image/png' | 'image/jpeg' | 'image/webp';
}

const defaultOptions = {
    maxSize: 1024,
    type: 'image/jpeg'
} as const;

const readImage = async (src: string): Promise<HTMLImageElement> => {
    const img = new Image();
    return new Promise((resolve, reject) => {
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (error) => reject(error));
        img.src = src;
    });
};

const readFile = async (file: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        // @ts-expect-error
        reader.addEventListener('load', (e: Event) => resolve(e.target.result));
        reader.addEventListener('error', (error) => reject(error));
        reader.readAsDataURL(file);
    });

interface SimplifiedImageInterface {
    image: HTMLImageElement;
    name: string;
    orientation: number;
}

const getImagesWithRotationData = async (
    files: File[]
): Promise<
    {
        image: HTMLImageElement;
        name: string;
        orientation: number;
    }[]
> =>
    Promise.all(
        files.map(async (file) => {
            const buffer = await file.arrayBuffer();

            let orientation;
            try {
                // images with no exif data will throw here
                const exifData = ExifReader.load(buffer, { expanded: false }) ?? {};
                orientation = exifData?.Orientation;
            } catch (err) {
                orientation = {};
            }

            const readData = await readFile(file);
            const image = await readImage(readData);

            return {
                image,
                name: file?.name as string,
                orientation: orientation?.value && Number.isFinite(orientation?.value) ? orientation.value : 1
            } as SimplifiedImageInterface; // due to typescript variation coming from exifreader
            // https://github.com/mattiasw/ExifReader/compare/4.11.1...4.12.0
            // https://github.com/mattiasw/ExifReader/issues/27
        })
    );

const getSize = (width: number, height: number, maxSize: number = defaultOptions.maxSize) => {
    const parseWidth = maxSize < width ? maxSize : width;
    const parseHeight = maxSize < height ? maxSize : height;
    if (width > height) {
        return {
            width: parseWidth,
            height: height * (parseWidth / width)
        };
    }

    if (height > width) {
        return {
            width: width * (parseHeight / height),
            height: parseHeight
        };
    }

    return {
        width: parseWidth,
        height: parseHeight
    };
};

export const getBase64Strings = async (
    files: File[],
    { maxSize = defaultOptions.maxSize, type = defaultOptions.type }: TOptions = {}
): Promise<ImageUpload[]> => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('canvas can not created');
    }

    const imagesWithData = await getImagesWithRotationData(files);

    const uploads = imagesWithData.map(({ image, name, orientation }) => {
        const { width, height } = getSize(image.width, image.height, maxSize);

        // for images which are not square,
        // fill the canvas with black (cinema style) and center the image on the axis with less-length
        if (width > height) {
            canvas.setAttribute('width', `${width}px`);
            canvas.setAttribute('height', `${width}px`);
            const y = (width - height) / 2;
            context.fillStyle = 'black';
            context.fillRect(0, 0, width, width);
            context.drawImage(image, 0, y, width, height);
        } else if (height > width) {
            canvas.setAttribute('width', `${height}px`);
            canvas.setAttribute('height', `${height}px`);
            const x = (height - width) / 2;
            context.fillStyle = 'black';
            context.fillRect(0, 0, height, height);
            context.drawImage(image, x, 0, width, height);
        } else {
            canvas.setAttribute('width', `${width}px`);
            canvas.setAttribute('height', `${height}px`);
            context.drawImage(image, 0, 0, width, height);
        }

        context.save();

        return {
            dataUri: canvas.toDataURL(type),
            name
        };
    });

    return uploads;
};
