import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import type { MessageGraph, MessageRecipient } from '@bladebinge/types';
import { useMe } from '../../../context/me/me-context';
import { MessageReplyBox } from '../../FormComponents/MessageReplyBox';
import { useHasMounted } from '../../../hooks/use-has-mounted';
import { useMessageThread } from '../../../hooks/react-query/messaging/message-thread';
import { MessageThreadView } from './MessageThreadView';

export const MessageThreadWrapper = ({ threadMessage }: { readonly threadMessage: MessageGraph }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const { id: userId } = useMe();
    const scrollToBottomRef = useRef<MutableRefObject<HTMLDivElement | undefined>>();
    const hasMounted = useHasMounted();
    const { query } = router;

    /* eslint-disable react-hooks/exhaustive-deps */
    // Build out recipient information
    const { allRecipients, threadUserIds } = useMemo<{
        allRecipients: MessageRecipient[];
        loggedInUserRecipientRecordForThread?: MessageRecipient;
        threadUserIds: string[];
    }>(() => {
        const { creatorId: activeMessageCreatorId, recipients = [] } = threadMessage ?? {};

        const { allRecipientsSet, loggedInUserAsRecipientRecord, threadUserIdsSet } = recipients.reduce<{
            allRecipientsSet: Set<MessageRecipient>;
            loggedInUserAsRecipientRecord?: MessageRecipient;
            threadUserIdsSet: Set<string>;
        }>(
            (acc, recipient) => {
                if (activeMessageCreatorId) {
                    acc.threadUserIdsSet.add(activeMessageCreatorId);
                }

                const recipientUserId = recipient?.recipientUserId;
                if (recipientUserId && !acc.threadUserIdsSet.has(recipientUserId)) {
                    acc.threadUserIdsSet.add(recipientUserId);
                    acc.allRecipientsSet.add(recipient);
                }

                if (recipient?.recipientUserId === userId) {
                    acc.loggedInUserAsRecipientRecord = recipient;
                }

                return acc;
            },
            {
                allRecipientsSet: new Set<MessageRecipient>(),
                loggedInUserAsRecipientRecord: undefined,
                threadUserIdsSet: new Set()
            }
        );

        return {
            allRecipients: Array.from(allRecipientsSet),
            loggedInUserRecipientRecordForThread: loggedInUserAsRecipientRecord,
            threadUserIds: Array.from(threadUserIdsSet.add(userId as string))
        };
    }, [threadMessage]);
    /* eslint-enable react-hooks/exhaustive-deps */

    // Note: may need some love if we ever message to groups in threads
    const recipientOfReply =
        threadMessage?.creatorId && threadMessage?.creatorId === userId
            ? allRecipients.find(({ recipientUserId }) => recipientUserId !== userId)
            : {
                  recipientUserId: threadMessage?.creatorId,
                  recipientUserProfileId: threadMessage?.creatorUserProfileId
              };

    const {
        data: threadMessageData,
        error: loadMessageThreadError,
        isLoading,
        isPending,
        fetchPreviousPage,
        hasPreviousPage
    } = useMessageThread({
        hasMounted,
        query,
        parentMessageId: threadMessage.id,
        threadUserIds,
        threadMessageCreatorId: threadMessage.creatorId,
        userId: userId as string
    });

    const isInPendingDataState = isLoading || isPending;

    /* eslint-disable react-hooks/exhaustive-deps */
    const scrollToBottomOfChat = useCallback(() => {
        if (!hasMounted || isInPendingDataState) {
            return;
        }

        if (scrollToBottomRef.current) {
            // @ts-expect-error
            scrollToBottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    // scroll to bottom of thread on initial render
    useEffect(() => {
        if (hasMounted) {
            scrollToBottomOfChat();
        }

        () => {};
    }, [hasMounted, scrollToBottomOfChat]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const paginatedMessages = useMemo(
        () =>
            (threadMessageData?.pages ?? []).reduce<MessageGraph[]>((messageAcc, page) => {
                messageAcc = [...messageAcc, ...(page?.result ?? [])];
                return messageAcc;
            }, []),
        [threadMessageData]
    );

    if (!userId || !hasMounted || !threadMessageData) {
        return null;
    }

    if (paginatedMessages && paginatedMessages.length > 0 && !threadMessage) {
        return (
            <Grid container spacing={1}>
                <Grid sx={{ p: 1 }} size={12}>
                    <Alert severity="error">{t('common:messaging.message_unavailable')}</Alert>
                </Grid>
            </Grid>
        );
    }

    const fetchPreviousPageData = () => {
        fetchPreviousPage();
    };

    return (
        <Paper elevation={3}>
            <Grid container>
                {hasPreviousPage && !isInPendingDataState ? (
                    <Grid sx={{ textAlign: 'center', my: 2 }} size={12}>
                        <Button onClick={fetchPreviousPageData} color="inherit" variant="contained" size="small">
                            {t('common:messaging.load_previous_messages')}
                        </Button>
                    </Grid>
                ) : null}
                <Grid size={12}>
                    <MessageThreadView
                        key={threadMessage.id}
                        loading={isInPendingDataState}
                        messages={paginatedMessages}
                    />
                </Grid>
                {loadMessageThreadError && (
                    <Grid container sx={{ p: 1 }}>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 12,
                                lg: 12,
                                xl: 12
                            }}
                        >
                            <Alert severity="error">{loadMessageThreadError?.message}</Alert>
                        </Grid>
                    </Grid>
                )}
                <Grid size={12}>
                    {/* @ts-expect-error */}
                    <div ref={scrollToBottomRef} />
                    <MessageReplyBox
                        recipientUserId={recipientOfReply?.recipientUserId}
                        recipientUserProfileId={recipientOfReply?.recipientUserProfileId}
                        parentMessageId={threadMessage.id}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};
