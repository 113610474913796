import React from 'react';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { displayCurrency } from '@bladebinge/web-service-common/src/utils/display-currency';
import Typography from '@mui/material/Typography';
import { type TypographyVariant } from '@mui/material/styles/createTypography';

export const ListingDisplayPrice = ({
    color = 'info.main',
    component = 'p',
    currency,
    fontFamily = headingFont.style.fontFamily,
    fontSize = '1.25em',
    fontWeight = 900,
    price,
    sx,
    variant = 'body1'
}: {
    readonly color?: string;
    readonly component?: string;
    readonly currency?: string | null;
    readonly fontFamily?: string;
    readonly fontSize?: string;
    readonly fontWeight?: number | string;
    readonly price: number;
    readonly sx?: { [jsonKey: string]: unknown };
    readonly variant?: TypographyVariant;
}) => (
    <Typography
        sx={{
            color,
            lineHeight: fontSize,
            fontFamily,
            fontSize,
            fontWeight,
            ...(sx ? sx : {})
        }}
        variant={variant}
        {...(component ? { component } : {})}
    >
        {displayCurrency(price, currency)}
    </Typography>
);
