import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SellIcon from '@mui/icons-material/Sell';
import { useTranslation } from 'next-i18next';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { useMe } from '../../context/me/me-context';
import { StyledNoUnderlineLink } from '../styled-shared';

export const SalesDriverButton = ({ className = '' }: { readonly className?: string }) => {
    const { t } = useTranslation();
    const { id: loggedInUserId } = useMe();

    const isSignedIn = Boolean(loggedInUserId);
    const getStartedUrl = '/get_started';
    const linkUrl = isSignedIn ? `/users/${loggedInUserId}/account?view=my_listings&formFlow=0` : getStartedUrl;

    return (
        <Grid className={className} sx={{ display: 'inline', m: 0, p: 0, alignItems: 'center' }}>
            <StyledNoUnderlineLink href={linkUrl}>
                <Button
                    variant="text"
                    color="secondary"
                    data-testid="sales_driver_btn"
                    size="small"
                    title={t('common:navigation.start_selling')}
                    fullWidth
                    sx={{
                        minHeight: '35px',
                        minWidth: 'auto',
                        fontSize: '1.25em',
                        fontFamily: `${headingFont.style.fontFamily}`,
                        fontWeight: 'bold',
                        mx: 0
                    }}
                >
                    <SellIcon
                        titleAccess={t('common:navigation.start_selling')}
                        sx={{ fontSize: 'inherit', verticalAlign: 'middle', mr: { xs: 0.25, sm: 0.75 } }}
                    />
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{
                            display: {
                                // xs: 'none',
                                sm: 'inline'
                            },
                            fontSize: {
                                xs: '0.9rem',
                                sm: '1.2rem',
                                md: '1.5rem',
                                lg: '1.55rem'
                            }
                        }}
                    >
                        {t('common:navigation.start_selling')}
                    </Typography>
                </Button>
            </StyledNoUnderlineLink>
        </Grid>
    );
};
