import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { StyledDefaultTextNoUnderlineLink } from '../styled-shared';

export const BladebingeShopIconLink = ({
    className = '',
    useBlankTarget = false
}: {
    readonly className?: string;
    readonly useBlankTarget?: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <Grid className={className}>
            <StyledDefaultTextNoUnderlineLink
                href="https://www.bladebingeshop.com"
                title={t('common:navigation.shop_thousands_of_new_edc_items_at')}
                {...(useBlankTarget ? { target: '_blank' } : {})}
                data-testid="bladebinge-shop-link"
            >
                <img
                    alt={t('common:bladebinge_shop.logo_alt')}
                    width="65px"
                    src={getCdnUrl('/logos/bladebinge_logo_white_230w_80h.png')}
                />
                <Typography
                    variant="h6"
                    color="primary"
                    component="span"
                    sx={{
                        display: 'inline-block',
                        position: 'relative',
                        bottom: '12px',
                        fontWeight: 'bold',
                        fontSize: '.85em',
                        ml: 0.15
                    }}
                >
                    {t('common:bladebinge_shop.shop_upcase')}
                </Typography>
                <Typography
                    variant="body2"
                    color="inherit"
                    component="span"
                    sx={{
                        display: 'inline-block',
                        position: 'relative',
                        bottom: '12px',
                        fontWeight: 'bold',
                        fontSize: '.65em'
                    }}
                >
                    {'.com'}
                </Typography>
            </StyledDefaultTextNoUnderlineLink>
        </Grid>
    );
};
