import React, { useCallback, useMemo } from 'react';
import querystring from 'query-string';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import NativeSelect from '@mui/material/NativeSelect';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { default as MuiPagination } from '@mui/material/Pagination';
import { queryWithFilteredParams } from '../../utils/query-with-filtered-params';

export const DEFAULT_PAGING_LIMIT = 25; // should match API default paging limit
export const PAGING_MAX_LIMIT = 200;
export const PAGING_LIMIT_OPTIONS = [5, 10, 15, 20, 25, 50, 100, 200];

export const Pagination = ({
    className,
    direction = 'column',
    limitPreference,
    filteredRouteParams = [],
    onUpdatePagingLimit,
    result = [],
    total
}: {
    readonly className?: string;
    readonly direction?: 'column' | 'row';
    readonly filteredRouteParams?: string[];
    readonly limitPreference?: number;
    readonly onUpdatePagingLimit?: (limit: number) => void;
    readonly result?: string[] | null | unknown[];
    readonly total?: number;
}) => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        asPath,
        query: { limit: queryLimit = '', offset: queryOffset = '0', ...restOfQuery }
    } = router;

    const [baseUrl, _] = asPath.split('?');

    const fallbackPagingLimit = limitPreference ?? DEFAULT_PAGING_LIMIT;

    const rawLimit = !queryLimit || Array.isArray(queryLimit) ? fallbackPagingLimit : parseInt(queryLimit, 10);
    const limit = Math.min(rawLimit, PAGING_MAX_LIMIT);
    const offset = Array.isArray(queryOffset) ? 0 : parseInt(queryOffset, 10);

    const pagingLimitOptionsWithCurrentLimit = useMemo(
        () => (PAGING_LIMIT_OPTIONS.includes(limit) ? PAGING_LIMIT_OPTIONS : [limit, ...PAGING_LIMIT_OPTIONS]),
        [limit]
    );

    const pageOptions: React.ReactNode[] = useMemo(
        () =>
            pagingLimitOptionsWithCurrentLimit.map((v) => (
                <option key={`per_page_${v}`} value={v}>
                    {v}
                </option>
            )),
        [pagingLimitOptionsWithCurrentLimit]
    );

    const setPageHandler = useCallback(
        (event: React.ChangeEvent<unknown>, page: number) => {
            const zeroBasedPage = page - 1;
            event.stopPropagation();
            router.push(
                `${baseUrl}?${querystring.stringify({
                    ...queryWithFilteredParams({ query: restOfQuery, filteredParams: filteredRouteParams }),
                    limit,
                    offset: zeroBasedPage * limit
                })}`
            );
        },
        [baseUrl, filteredRouteParams, limit, restOfQuery, router]
    );

    const setLimitHandler = useCallback(
        (event: React.ChangeEvent<{ value: string }>) => {
            const updatedLimit = parseInt(event.target.value, 10);

            if (typeof onUpdatePagingLimit === 'function') {
                onUpdatePagingLimit(updatedLimit);
                return;
            }

            router.push(
                `${baseUrl}?${querystring.stringify({
                    ...queryWithFilteredParams({ query: restOfQuery, filteredParams: filteredRouteParams }),
                    limit: updatedLimit,
                    offset: 0
                })}`
            );
        },
        [baseUrl, filteredRouteParams, onUpdatePagingLimit, restOfQuery, router]
    );

    if (!total) {
        return null;
    }

    const page = Math.ceil(offset / limit + 1);
    const count = Math.ceil(total / limit);

    const curPageParams = {
        start: limit * (page - 1) + 1, // 1 based
        end: limit * (page - 1) + (result || []).length
    };

    return (
        <Grid
            container
            sx={{
                px: {
                    xs: 0,
                    sm: 1
                }
            }}
            className={className}
            direction={direction}
            alignItems="center"
            justifyContent="center"
        >
            <Grid size={12}>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} variant="body2">
                    {t('general.current_page_info', curPageParams)}
                    <Typography
                        className="pagination-total"
                        data-paginationtotal={total}
                        sx={{ mx: 1, fontStyle: 'italic' }}
                        variant="caption"
                    >
                        {t('general.page_total_info', { total })}
                    </Typography>
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between" size={12}>
                <Grid size={9}>
                    <MuiPagination
                        color="standard"
                        count={count}
                        onChange={setPageHandler}
                        page={page}
                        sx={{ my: 1 }}
                        variant="outlined"
                        boundaryCount={1}
                        size="small"
                    />
                </Grid>
                <Grid size={3}>
                    <div>
                        <NativeSelect
                            value={limit}
                            onChange={setLimitHandler}
                            inputProps={{
                                name: t('general.items_per_page'),
                                id: 'pagination_per_page'
                            }}
                        >
                            {pageOptions}
                        </NativeSelect>
                        <FormHelperText sx={{ textAlign: 'center' }}>{t('general.items_per_page')}</FormHelperText>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};
