import React, { useCallback, useMemo, useState } from 'react';
import currency from 'currency.js';
import { useTranslation } from 'next-i18next';
import 'react-phone-number-input/style.css';
import TextField from '@mui/material/TextField';
import { getCurrencySymbol } from '@bladebinge/web-service-common/src/utils/get-currency-symbol';
import type { ListingGraph, PurchaseOfferListingGraph } from '@bladebinge/types';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { displayCurrency } from '@bladebinge/web-service-common/src/utils/display-currency';
import { useMakeAnOfferContext } from '../../../context/make-an-offer/make-an-offer-context';

export const OfferPriceEntryForm = ({
    isReadonly = false,
    listingGraph,
    originalPurchaseOfferListing,
    isTradeListing = false
}: {
    readonly isReadonly?: boolean;
    readonly listingGraph: ListingGraph;
    readonly originalPurchaseOfferListing?: PurchaseOfferListingGraph;
    readonly isTradeListing?: boolean;
}) => {
    const { t } = useTranslation();
    const {
        parentOfferId,
        offerListingDataByListingIdMap,
        offerTradeDataByListingIdMap,
        purchaseOfferSuccess,
        setOfferDataForListing,
        setTradeDataForListing
    } = useMakeAnOfferContext();
    const originalMarkeplacePrice = listingGraph?.price ? `${listingGraph?.price}` : '0';
    const initialOfferPrice = isTradeListing
        ? (offerTradeDataByListingIdMap.get(listingGraph.id)?.unitPrice ?? originalMarkeplacePrice)
        : (offerListingDataByListingIdMap.get(listingGraph.id)?.unitPrice ?? originalMarkeplacePrice);

    const [priceValue, setPriceValue] = useState<string>(`${initialOfferPrice}`);

    const isCounterOffer = useMemo(() => Boolean(parentOfferId), [parentOfferId]);

    const currentOfferQuantity = useMemo(
        () =>
            isTradeListing
                ? (offerTradeDataByListingIdMap?.get(listingGraph.id)?.quantity ?? 1)
                : (offerListingDataByListingIdMap?.get(listingGraph.id)?.quantity ?? 1),
        [isTradeListing, listingGraph.id, offerListingDataByListingIdMap, offerTradeDataByListingIdMap]
    );

    const currentOfferPrice = useMemo(
        () =>
            isTradeListing
                ? (offerTradeDataByListingIdMap?.get(listingGraph.id)?.unitPrice ?? listingGraph.price)
                : (offerListingDataByListingIdMap?.get(listingGraph.id)?.unitPrice ?? listingGraph.price),
        [
            isTradeListing,
            listingGraph.id,
            listingGraph.price,
            offerListingDataByListingIdMap,
            offerTradeDataByListingIdMap
        ]
    );

    const changeContextPrice = useCallback(
        (updatedPrice: number) => {
            if (updatedPrice && updatedPrice !== currentOfferPrice) {
                const setMethod = isTradeListing ? setTradeDataForListing : setOfferDataForListing;
                setMethod(listingGraph, {
                    quantity: isTradeListing
                        ? (offerTradeDataByListingIdMap.get(listingGraph.id)?.quantity ?? 1)
                        : (offerListingDataByListingIdMap.get(listingGraph.id)?.quantity ?? 1),
                    unitPrice: currency(updatedPrice).value
                });
            }
        },
        [
            currentOfferPrice,
            isTradeListing,
            listingGraph,
            offerListingDataByListingIdMap,
            offerTradeDataByListingIdMap,
            setOfferDataForListing,
            setTradeDataForListing
        ]
    );

    const handlePercentageButtonClick = useCallback(
        (percentageChange: number) => {
            const updatedPrice = listingGraph.price - listingGraph.price * percentageChange;
            setPriceValue(updatedPrice.toFixed(2));
            changeContextPrice(updatedPrice);
        },
        [changeContextPrice, listingGraph.price]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const price = e.target?.value ?? 0;
        const parsedPrice = parseFloat(price);
        setPriceValue(price);
        if (parsedPrice) {
            changeContextPrice(parsedPrice ?? listingGraph.price);
        }
    };

    const offerPriceLabel = useMemo(
        () =>
            t('common:purchase_offer.offer_price', {
                count: currentOfferQuantity
            }),
        [currentOfferQuantity, t]
    );

    const buttonOfferKey = useMemo(
        () => (isCounterOffer ? 'offer_less_counter_offer_label' : 'offer_less_label'),
        [isCounterOffer]
    );

    return (
        <Grid container>
            <Grid size={12}>
                <TextField
                    aria-required
                    disabled={purchaseOfferSuccess}
                    id={`${listingGraph.id}_offer_price`}
                    slotProps={{
                        inputLabel: {
                            shrink: true
                        },
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {getCurrencySymbol(listingGraph.currency ?? 'usd')}
                                </InputAdornment>
                            )
                        }
                    }}
                    label={offerPriceLabel}
                    margin="none"
                    onChange={handleInputChange}
                    placeholder={offerPriceLabel}
                    aria-readonly={isReadonly}
                    required
                    type="text"
                    value={priceValue}
                    variant="standard"
                />
            </Grid>
            {!isReadonly && !purchaseOfferSuccess && (
                <Grid sx={{ mt: 0.5 }} size={12}>
                    <ButtonGroup aria-label="Vertical button group" size="small" fullWidth>
                        <Button
                            aria-label={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '10'
                            })}
                            className="offer-10-percent-off-button"
                            onClick={() => handlePercentageButtonClick(0.1)}
                            title={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '10'
                            })}
                        >
                            {t('common:purchase_offer.percentage_discounts.ten')}
                        </Button>
                        <Button
                            aria-label={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '15'
                            })}
                            onClick={() => handlePercentageButtonClick(0.15)}
                            title={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '15'
                            })}
                        >
                            {t('common:purchase_offer.percentage_discounts.fifteen')}
                        </Button>
                        <Button
                            aria-label={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '20'
                            })}
                            onClick={() => handlePercentageButtonClick(0.2)}
                            title={t(`common:purchase_offer.percentage_discounts.${buttonOfferKey}`, {
                                percentage: '20'
                            })}
                        >
                            {t('common:purchase_offer.percentage_discounts.twenty')}
                        </Button>
                    </ButtonGroup>
                    <Typography variant="caption" component="div" sx={{ textAlign: 'center' }}>
                        {isTradeListing
                            ? t('common:purchase_offer.offer_discount')
                            : t('common:purchase_offer.request_discount')}
                    </Typography>
                </Grid>
            )}
            {isCounterOffer && originalPurchaseOfferListing && (
                <Grid sx={{ mt: 0.5 }} size={12}>
                    <Typography variant="caption">
                        {t('common:purchase_offer.counter_offer_original_price', {
                            price: displayCurrency(originalPurchaseOfferListing.unitPrice, listingGraph?.currency)
                        })}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
