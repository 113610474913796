import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import NativeSelect from '@mui/material/NativeSelect';
import FormHelperText from '@mui/material/FormHelperText';
import { PaginationLinks } from '@bladebinge/types';
import Typography from '@mui/material/Typography';
import { clientOrServerApiUrlResolver } from '../../server/api-proxy/utils/client-or-server-api-url-resolver';

// Paging in this case is handled manually by in-poage-state
export const DEFAULT_PAGING_LIMIT = 5;
export const PAGING_MAX_LIMIT = 25;
export const PAGING_LIMIT_OPTIONS = [5, 10, 15, 20, 25];

export const PaginationByLinks = ({
    className,
    currentPageItemsCount,
    links,
    defaultLimit = 5,
    handleSetPagingParams,
    total
}: {
    readonly className?: string;
    readonly currentPageItemsCount: number;
    readonly defaultLimit?: number;
    readonly links?: PaginationLinks;
    readonly handleSetPagingParams: (params: { limit: number; offset: number }) => void;
    readonly total: number;
}) => {
    const { t } = useTranslation();

    const { currentLimit, currentOffset, nextOffset, prevOffset } = useMemo(() => {
        // let currentOffset = 0;
        let _nextOffset: number | null = null;
        let _prevOffset: number | null = null;
        let _currentLimit = defaultLimit;
        let _currentOffset = defaultLimit;

        try {
            const selfLink = links?.self?.href ? new URL(clientOrServerApiUrlResolver(links.self.href)) : undefined;
            if (selfLink) {
                const urlLimit = selfLink.searchParams.get('limit');
                const urlOffset = selfLink.searchParams.get('offset');
                _currentLimit = urlLimit && urlLimit !== null ? parseInt(urlLimit, 10) : defaultLimit;
                _currentOffset = urlOffset && urlOffset !== null ? parseInt(urlOffset, 10) : defaultLimit;
            }

            const nextLink = links?.next?.href ? new URL(clientOrServerApiUrlResolver(links.next.href)) : undefined;
            if (nextLink) {
                const nextUrlOffset = nextLink.searchParams.get('offset');
                _nextOffset = nextUrlOffset && nextUrlOffset !== null ? parseInt(nextUrlOffset, 10) : 0;
            }

            const prevLink = links?.prev?.href ? new URL(clientOrServerApiUrlResolver(links.prev.href)) : undefined;
            if (prevLink) {
                const prevUrlOffset = prevLink.searchParams.get('offset');
                _prevOffset = prevUrlOffset && prevUrlOffset !== null ? parseInt(prevUrlOffset, 10) : 0;
            }
        } catch (err) {
            // noop just avoid url parsing errors
        }

        return {
            currentOffset: _currentOffset,
            currentLimit: _currentLimit,
            nextOffset: _nextOffset,
            prevOffset: _prevOffset
        };
    }, [defaultLimit, links]);

    const setLimitHandler = (event: React.ChangeEvent<{ value: string }>) => {
        const updatedLimit = parseInt(event.target.value, 10);
        handleSetPagingParams({
            limit: updatedLimit,
            offset: 0
        });
    };

    const setOffsetHandler = (offset: number | null) => {
        if (offset === null) {
            return;
        }

        handleSetPagingParams({
            limit: currentLimit,
            offset
        });
    };

    const pagingLimitOptionsWithCurrentLimit = useMemo(
        () =>
            PAGING_LIMIT_OPTIONS.includes(currentLimit)
                ? PAGING_LIMIT_OPTIONS
                : [currentLimit, ...PAGING_LIMIT_OPTIONS],
        [currentLimit]
    );

    const pageOptions: React.ReactNode[] = useMemo(
        () =>
            pagingLimitOptionsWithCurrentLimit.map((v) => (
                <option key={`per_page_${v}`} value={v}>
                    {v}
                </option>
            )),
        [pagingLimitOptionsWithCurrentLimit]
    );

    if (!total || currentLimit >= total) {
        return null;
    }

    const page = currentOffset / currentLimit + 1;

    const curPageParams = {
        start: currentLimit * (page - 1) + 1, // 1 based
        end: currentLimit * (page - 1) + currentPageItemsCount
    };

    return (
        <Grid container className={className} alignItems="flex-end" justifyContent="flex-end">
            <Grid size={3}>
                <Box sx={{ textAlign: 'right', pr: 2 }}>
                    <NativeSelect
                        value={currentLimit}
                        onChange={setLimitHandler}
                        inputProps={{
                            name: t('general.items_per_page'),
                            id: 'pagination_per_page'
                        }}
                    >
                        {pageOptions}
                    </NativeSelect>
                    <FormHelperText sx={{ textAlign: 'right' }}>{t('general.items_per_page')}</FormHelperText>
                </Box>
            </Grid>
            <Grid>
                <div>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} variant="body2">
                        {t('general.current_page_info', curPageParams)}
                        <Typography
                            className="pagination-total"
                            data-paginationtotal={total}
                            sx={{ mx: 1, fontStyle: 'italic' }}
                            variant="caption"
                        >
                            {t('general.page_total_info', { total })}
                        </Typography>
                    </Typography>
                </div>
                <div>
                    <Button
                        color="primary"
                        disabled={prevOffset === null}
                        aria-label={t('common:time_ranges.select_custom_time_range')}
                        onClick={() => setOffsetHandler(prevOffset)}
                        title={t('common:time_ranges.select_custom_time_range')}
                        variant="contained"
                        sx={{ m: 0.5, py: 1, px: 0, minWidth: '45px' }}
                    >
                        <ArrowBackIosIcon fontSize="medium" />
                    </Button>
                    <Button
                        color="primary"
                        disabled={nextOffset === null}
                        aria-label={t('common:time_ranges.select_custom_time_range')}
                        onClick={() => setOffsetHandler(nextOffset)}
                        title={t('common:time_ranges.select_custom_time_range')}
                        variant="contained"
                        sx={{ m: 0.5, py: 1, px: 0, minWidth: '45px' }}
                    >
                        <ArrowForwardIosIcon fontSize="medium" />
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};
