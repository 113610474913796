import React from 'react';
import { default as NextImage } from 'next/image';
import { useTranslation } from 'next-i18next';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { UserProfileDisplayGraph } from '@bladebinge/types';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { getAverageRating } from '../../utils/get-average-rating';
import { StyledDefaultTextNoUnderlineLink } from '../styled-shared';
import { AverageRating } from './AverageRating';

export const UserProfileWithAverageRating = ({
    baseUrl = '',
    gridDirection = 'row',
    profile,
    showBorder = true,
    size = 'medium'
}: {
    readonly baseUrl?: string;
    readonly gridDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
    readonly profile: UserProfileDisplayGraph;
    readonly showBorder?: boolean;
    readonly size?: 'small' | 'medium' | 'large';
}) => {
    const { t } = useTranslation();

    if (!profile) {
        return null;
    }

    const {
        avatar,
        owner: { ratingsReceived = [] },
        username
    } = profile;

    const averageRating = getAverageRating(ratingsReceived);

    return (
        <Grid
            container
            direction={gridDirection}
            sx={{
                my: 1.5,
                ...(showBorder
                    ? {
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: 'divider',
                          p: 1,
                          minHeight: '58px'
                      }
                    : {})
            }}
        >
            <Grid size={3}>
                <StyledDefaultTextNoUnderlineLink
                    href={`${baseUrl}/profiles/${username}`}
                    title={t('common:user_profile.view_profile_for', { username })}
                >
                    <Avatar
                        className="user-avatar-with-rating"
                        data-username={username}
                        sx={{
                            maxWidth: '96%',
                            width: {
                                xs: '38px',
                                sm: '55px'
                            },
                            height: {
                                xs: '38px',
                                sm: '55px'
                            }
                        }}
                        component="span"
                    >
                        {avatar?.thumbnailUrl ? (
                            <NextImage src={getCdnUrl(avatar.thumbnailUrl)} alt={username} fill priority />
                        ) : (
                            username.charAt(0)
                        )}
                    </Avatar>
                </StyledDefaultTextNoUnderlineLink>
            </Grid>
            <Grid container justifyContent="flex-start" size={9}>
                <Grid size={12}>
                    <StyledDefaultTextNoUnderlineLink
                        href={`/profiles/${username}`}
                        title={t('common:user_profile.view_profile_for', { username })}
                    >
                        <Typography
                            variant="subtitle1"
                            title={username}
                            sx={{
                                fontFamily: headingFont.style.fontFamily,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {username}
                        </Typography>
                    </StyledDefaultTextNoUnderlineLink>
                </Grid>
                <Grid size={12}>
                    <AverageRating averageRating={averageRating} convertToStarRating size={size} />
                    <Typography variant="caption">({ratingsReceived.length})</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
